const synthesizeChilds = [
  {
    path: "monitor",
    name: "synthesizeMonitor",
    component: () => import("@/views/synthesize/statistics.vue"),
    children: [
      {
        path: "monitorLive",
        name: "MonitorLive",
        component: () => import("@/views/synthesize/children/monitorLive.vue"),
      },
      {
        path: "monitorFile",
        name: "MonitorFile",
        component: () => import("@/views/synthesize/children/monitorFile.vue"),
      },
      {
        path: "playback",
        name: "playback",
        component: () => import("@/views/synthesize/children/playback.vue"),
      },
    ],
  },
];
export default synthesizeChilds;
