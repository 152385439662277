import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from '@/plugins/element'

/** 按钮路由组件 */
import buttonRouter from "@/components/button-router/button-router.vue";
// 导入公共表格组件
import commonTable from "@/components/common-table/common-table.vue";
// 导入弹框组件
import wDialog from "@/components/w-dialog/w-dialog.vue";
// 导入软键盘组件
import keyboard from "@/components/key-board/keyboard.vue";

const app = createApp(App)

app.component('button-router', buttonRouter)
app.component('common-table', commonTable)
app.component('w-dialog', wDialog)
app.component('key-board', keyboard)

/**
 * 缓存的主题色
 */
const activeTheme = sessionStorage.getItem('activeTheme')
store.commit('setActiveTheme', activeTheme ? activeTheme : 'blue')

/**
 * 样式文件引入
 */
import '@/styles/reset.css';
import '@/styles/theme/theme.scss';
import '@/styles/alibaba/iconfont.css';
import '@/styles/alibaba/mileIsland/iconfont.css';
import '@/styles/index.scss';

/**
 * element ui 挂载
 */
installElementPlus(app)

app.use(store)
app.use(router)
app.mount('#app')
