const analyseChilds = [
  {
    path: "statistics",
    name: "analyseStatistics",
    component: () => import("@/views/analyse/statistics.vue"),
    children: [
      // 园区汇总
      {
        path: "scenic",
        name: "analyseScenic",
        component: () => import("@/views/analyse/children/scenic.vue"),
      },
      // 门店汇总
      {
        path: "store",
        name: "analyseStore",
        component: () => import("@/views/analyse/children/store.vue"),
      },
      // 成本分摊汇总
      {
        path: "cost",
        name: "analyseCost",
        component: () => import("@/views/analyse/children/cost.vue"),
      },
      {
        path: "agent",
        name: "analyseAgent",
        component: () => import("@/views/analyse/children/agent.vue"),
      },
      {
        path: "activity",
        name: "analyseActivity",
        component: () => import("@/views/analyse/children/activity.vue"),
      },
      {
        path: "hotel",
        name: "analyseHotel",
        component: () => import("@/views/analyse/children/hotel.vue"),
      },
    ],
  },
];
export default analyseChilds;
