const scenicChilds = [
  // {
  //   path: "stickets",
  //   name: "Stickets",
  //   component: () => import("@/views/scenic/stickets.vue"),
  //   children: [
  //     {
  //       path: "stmanage",
  //       name: "Stmanage",
  //       component: () => import("@/views/scenic/children/stmanage.vue"),
  //     },
  //   ],
  // },
  {
    path: "cashier",
    name: "cashier",
    component: () => import("@/views/scenic/cashier.vue"),
    children: [
     {
       path: "commodity",
       name: "Commodity",
       component: () => import("@/views/scenic/children/commodity.vue"),
     },
    ],
  },
  {
    path: "sale",
    name: "Sale",
    component: () => import("@/views/scenic/sale.vue"),
    children: [
      {
        path: "olsale",
        name: "Olsale",
        component: () => import("@/views/scenic/children/olsale.vue"),
      },
      {
        path: "aborigines",
        name: "Aborigines",
        component: () => import("@/views/scenic/children/aborigines.vue"),
      }
    ],
  },
  {
    path: "sconfig",
    name: "Sconfig",
    component: () => import("@/views/scenic/sconfig.vue"),
    children: [
      {
        path: "secnf",
        name: "Secnf",
        component: () => import("@/views/scenic/children/secnf.vue"),
      },
    ],
  },
  {
    path: "museum",
    name: "Museum",
    component: () => import("@/views/scenic/museum.vue"),
    children: [
      {
        path: "file",
        name: "File",
        component: () => import("@/views/scenic/children/FileManagement.vue"),
      },
      {
        path: "point",
        name: "Point",
        component: () => import("@/views/scenic/children/PointManagement.vue"),
      }
    ],
  },
  {
    path: "mscard",
    name: "Mscard",
    component: () => import("@/views/scenic/mscard.vue"),
    children: [
      {
        path: "membershipCard",
        name: "MembershipCard",
        component: () => import("@/views/scenic/children/membershipCard.vue"),
      },
    ],
  },
  {
    path: "woff",
    name: "Woff",
    component: () => import("@/views/scenic/woff.vue"),
    children: [
      {
        path: "writeOff",
        name: "WriteOff",
        component: () => import("@/views/scenic/children/writeOff.vue"),
      },
    ],
  },
  {
    path: "spass",
    name: "Spass",
    component: () => import("@/views/scenic/spass.vue"),
    children: [
      {
        path: "passCheck",
        name: "PassCheck",
        component: () => import("@/views/scenic/children/passCheck.vue"),
      },
    ],
  },
  {
    path: "mkitchen",
    name: "Mkitchen",
    component: () => import("@/views/scenic/mkitchen.vue"),
    children: [
      {
        path: "kitchenManage",
        name: "kitchenManage",
        component: () => import("@/views/scenic/children/kitchenManage.vue"),
      },
    ],
  },
];
export default scenicChilds;
