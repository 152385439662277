const orderChilds = [
  {
    path: "ohotel",
    name: "OrderHotelIndex",
    component: () => import("@/views/order/ohotel.vue"),
    children: [
      {
        path: "hotel",
        name: "OrderHotel",
        component: () => import("@/views/order/children/hotel.vue"),
      },
    ],
  },
  {
    path: "otickets",
    name: "OrderTicketsIndex",
    component: () => import("@/views/order/otickets.vue"),
    children: [
      {
        path: "tickets",
        name: "OrderTickets",
        component: () => import("@/views/order/children/tickets.vue"),
      },
    ],
  },
  {
    path: "opay",
    name: "OrderPayIndex",
    component: () => import("@/views/order/opay.vue"),
    children: [
      {
        path: "pay",
        name: "OrderPay",
        component: () => import("@/views/order/children/pay.vue"),
      },
    ],
  },
  {
    path: "oactivity",
    name: "OrderActivityIndex",
    component: () => import("@/views/order/oactivity.vue"),
    children: [
      {
        path: "activity",
        name: "OrderActivity",
        component: () => import("@/views/order/children/activity.vue"),
      },
    ],
  },
  {
    path: "oagent",
    name: "OrderAgentIndex",
    component: () => import("@/views/order/oagent.vue"),
    children: [
      {
        path: "agent",
        name: "OrderAgent",
        component: () => import("@/views/order/children/agent.vue"),
      },
    ],
  },
  {
    path: "rpremium",
    name: "OrderPremiumIndex",
    component: () => import("@/views/order/rpremium.vue"),
    children: [
      {
        path: "premium",
        name: "OrderPremium",
        component: () => import("@/views/order/children/premium.vue"),
      },
    ],
  },
  {
    path: "discount",
    name: "Discount",
    component: () => import("@/views/order/discount.vue"),
  },
];
export default orderChilds;
