const basicChilds = [{
		path: "user",
		name: "User",
		component: () => import("@/views/basic/user.vue"),
		children: [{
				path: "members",
				name: "Members",
				component: () => import("@/views/basic/children/members.vue"),
			},
			{
				path: "role",
				name: "Role",
				component: () => import("@/views/basic/children/role.vue"),
			}
		]
	},
	{
		path: "resource",
		name: "Resource",
		component: () => import("@/views/basic/resource.vue"),
		children: [{
				path: "resmanage",
				name: "Resmanage",
				component: () => import("@/views/basic/children/resmanage.vue"),
			},
			{
				path: "entrance",
				name: "Entrance",
				component: () => import("@/views/basic/children/entrance.vue"),
			},
			{
				path: "parking",
				name: "Parking",
				component: () => import("@/views/basic/children/parking.vue"),
			},
			{
				path: "stmanage",
				name: "Stmanage",
				component: () => import("@/views/basic/children/stmanage.vue"),
			},
			{
				path: "gate",
				name: "Gate",
				component: () => import("@/views/basic/children/gate.vue"),
			},
			{
				path: "monitor",
				name: "Monitor",
				component: () => import("@/views/basic/children/monitor.vue"),
			},
			{
				path: "iot",
				name: "Iot",
				component: () => import("@/views/basic/children/iot.vue"),
			}
		]
	},
	{
		path: "integrate",
		name: "Integrate",
		component: () => import("@/views/basic/integrate.vue"),
	},
	{
		path: "report",
		name: "Report",
		component: () => import("@/views/basic/report.vue"),
	},
	{
		path: "miniapp",
		name: "Miniapp",
		component: () => import("@/views/basic/miniapp.vue"),
		children: [{
			path: "minimanage",
			name: "Minimanage",
			component: () => import("@/views/basic/children/minimanage.vue"),
		}]
	},
	{
		path: "system",
		name: "System",
		component: () => import("@/views/basic/system.vue"),
	},
];
export default basicChilds;
