<template>
  <div v-if="dialogVisible" class="confirm-dialog">
    <el-dialog v-model="dialogVisible" width="420px" center top="30vh">
      <div class="confirm-tips">
        <img src="@/assets/img/warning.png" />
        <div class="confirm-title">{{ title }}</div>
      </div>
      <slot></slot>
      <template #footer>
        <el-button
          @click="dialogVisible = false"
          type="primary"
          class="cancle"
          plain
          round
          >返回</el-button
        >
        <el-button
          class="confirm"
          :class="sureDisabled ? 'disabled-btn' : ''"
          @click="wConfirm"
          round
          :disabled="sureDisabled"
          :loading="isLoading"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["wConfirm"],
  setup(props, { emit }) {
    const dialogVisible = ref(false);
    const sureDisabled = ref(false);

    const wConfirm = () => {
      // dialogVisible.value = false;
      emit("wConfirm", "1333");
    };
    const isLoading = ref(false);
    const show = () => {
      dialogVisible.value = true;
    };
    const close = () => {
      dialogVisible.value = false;
    };
    return {
      show,
      close,
      wConfirm,
      dialogVisible,
      isLoading,
      sureDisabled,
    };
  },
};
</script>

<style lang="scss">
.confirm-dialog {
  .el-dialog__header {
    background-color: var(--bg-content-color);
  }

  .confirm-tips {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 100px;
    }
    .confirm-title {
      font-size: 15px;
      font-weight: bold;
      color: var(--text-color);
    }
  }
  .cancle {
    border: 2px solid var(--theme-color);
    padding: 10px 60px;
    color: var(--theme-color);
    background-color: var(--text-white-color);
    &:hover {
      background-color: var(--theme-color) !important;
      color: var(--text-white-color) !important;
    }
  }
  .confirm {
    padding: 10px 60px;
    color: var(--text-white-color);
    margin-left: 0 !important;
  }
  .disabled-btn {
    opacity: 0.6;
    &:hover {
      opacity: 0.6;
    }
  }
  .el-dialog__footer {
    display: flex;
    justify-content: space-between;
    .el-button {
      width: auto;
    }
  }
  .el-dialog .el-dialog__footer .el-button {
    width: 160px;
  }
}
</style>
