const storeManageChilds = [
  // 门店管理
  {
    path: "madministra",
    name: "Madministra",
    component: () => import("@/views/storeManage/madministra.vue"),
    children: [
      {
        path: "administra",
        name: "Administra",
        component: () => import("@/views/storeManage/children/administra.vue"),
      },
    ],
  },
  {
    path: "mclassification",
    name: "Mclassification",
    component: () => import("@/views/storeManage/mclassification.vue"),
    children: [
      {
        path: "classification",
        name: "Classification",
        component: () => import("@/views/storeManage/children/classification.vue"),
      },
    ],
  },
];
export default storeManageChilds;
