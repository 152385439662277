<template>
  <div id="top" v-loading.fullscreen.lock="isLoading">
    <div class="header-container" v-if="!isLoading">
      <div v-show="isExpand" class="logo-container flex-center">
        <img class="logo" :src="userInfo.projectLogo" />
      </div>
      <div v-show="!isExpand" class="logo-container flex-center expand">
        <img class="logo" :src="userInfo.projectLogo" />
      </div>
      <i v-show="isExpand" class="iconfont icon-a-jianqu45" @click="expandChange"></i>
      <i v-show="!isExpand" class="iconfont icon-a-jianqu46" @click="expandChange"></i>
      <el-menu :default-active="activePath" mode="horizontal" :router="true">
        <el-menu-item :index="item.path" v-for="(item, index) in menus" :key="index">{{ item.title }}</el-menu-item>
      </el-menu>

      <el-button type="primary" plain round @click="onNumberplateBtn"
        v-if="authData.indexOf('n_77t3hWqErE3P66jjF5FRpyDFiUxU') != -1">车牌录入</el-button>

      <div class="header-right">
        <el-dropdown class="flex-center">
          <span class="el-dropdown-link">
            <i class="iconfont icon-a-zu4784"> </i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="(item, index) in skinData" @click="checkSkin(item)"
                :class="item.id === activeTheme ? 'active-skin' : ''" :key="index">
                {{ item.name }}
                <span v-if="item.id === activeTheme">(当前)</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span class="text-hidden">{{ userInfo.realname }}</span>
        <div class="text-hidden">{{ userInfo.posname }}</div>

        <i class="iconfont icon-a-zu4766" @click="handleOut" title="安全退出"></i>
      </div>
    </div>
    <!-- 确认订单弹窗 -->
    <confirm-dialog ref="showDialog" @wConfirm="exit" title="确认要退出系统吗？"></confirm-dialog>


    <!-- 车牌录入 -->
    <w-dialog ref="numberplateDialog" class="enter-dialog" title="车牌录入" width="50%" btnWidth="140px" top="4vh"
      confirmText="确认" @wConfirm="onConfirmEntry" :hideFooter="hideFooter">
      <div class="empty-parking-space">
        <div>
          <span class="parking-space-title">空余车位：</span>
          <span class="parking-space-number">{{ remainParkCount }}</span>
        </div>
        <div>注：空余车位不足，而停车场还有可用车位时，请联系运维修改空余车位数。</div>
      </div>
      <div>
        <LicensePlateKeyboard ref="licensePlateRef" @onQueryBtn="onQueryBtn" @inputEvent="inputEvent"
          :placeholder="'请输入车牌号'">
        </LicensePlateKeyboard>
      </div>
      <div v-if="isAdmissionInfo" style="margin-top: 20px;color: var(--text-color);">
        车辆进场时间：<span>{{ last_pass_time }}</span>
      </div>
      <div v-if="isNoData" style="margin-top: 20px;color: var(--text-color);padding: 20px 0;">
        暂未查到车辆入场信息
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, computed, watch, onMounted, nextTick, } from "vue";
  import { useStore } from "vuex";
  import { useRouter, useRoute } from "vue-router";
  import cookie from "@/utils/cookie";
  import { ElMessage } from "element-plus";
  import { FrameApi, SaleApi } from "@/plugins/api.js";
  import ConfirmDialog from "@/components/confirm-dialog/confirm-dialog.vue";
  import LicensePlateKeyboard from "@/components/key-board/LicensePlateKeyboard.vue";
  import { isVehicleNumber } from "@/utils/common.js";

  export default {
    components: {
      ConfirmDialog,
      LicensePlateKeyboard,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);  // 权限
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      /** 用户信息 */
      let userInfo = {
        realname: cookie.getCookie("user_name"),
        posname: cookie.getCookie("duty_name"),
        projectLogo:
          cookie.getCookie("project-logo") ||
          "https://z.ygddzy.cn/mld/source/logo-title.png",
      };
      if (!userInfo.realname) {
        userInfo.realname = sessionStorage.getItem("user_name")
        userInfo.posname = sessionStorage.getItem("duty_name")
      }

      /** 是否正在加载数据 */
      const isLoading = ref(false);

      /** 是否展开 */
      const isExpand = computed(() => store.state.Config.isExpand);

      /** 顶部 菜单导航列表 */
      const menus = computed(() => store.state.menuToken.menus);

      /** 当前选择的菜单 */
      const activePath = ref("");

      /** 是否显示退出系统弹框 */
      const showDialog = ref(null);

      /** 点击退出登录处理 */
      const handleOut = () => {
        showDialog.value.show();
      };

      /** 退出系统确认处理 */
      const exit = () => {
        // 清除cookie
        cookie.delCookie("user_name");
        cookie.delCookie("user_tel");
        cookie.delCookie("user_sex");
        cookie.delCookie("duty_name");
        cookie.delCookie("project-logo");
        sessionStorage.clear()
        router.push("/login");
        store.commit("setIsExpand", true);
        store.commit("menuTokenInit");
        store.commit("onMessage");
      };

      /** 换肤 */
      const skinData = ref([
        {
          name: "夏雨蓝",
          id: "blue",
        },
        {
          name: "薄雾黑",
          id: "black",
        },
        {
          name: "暖秋金",
          id: "gold",
        },
        {
          name: "春木绿",
          id: "green",
        },
        {
          name: "暗夜蓝",
          id: "late",
        },
      ]);

      /** 当前主题 */
      const activeTheme = computed(() => store.state.Config.activeTheme);

      /** 主题切换 */
      const checkSkin = (item) => {
        store.commit("setActiveTheme", item.id);
      };

      /** 路由改变处理 */
      const routeChange = (value, oldValue) => {
        if (oldValue.startsWith(value)) {
          router.push(oldValue);
        } else {
          if (!value || value === "/") {
            activePath.value = menus.value[0].path;
          } else {
            activePath.value = "/" + value.split("/")[1];
          }
        }
        let index = menus.value.findIndex(
          (item) => item.path === activePath.value
        );
        store.commit("setModuleIndex", index === -1 ? 0 : index);
      };

      /** 展开 收起 */
      const expandChange = () => {
        store.commit("setIsExpand", !isExpand.value);
      };

      /** 获取模块权限 */
      const getUserRole = () => {
        isLoading.value = true;
        FrameApi.getUserRole().then((res) => {
          if (res.Code === 200) {
            store.commit("setModuleTokens", res.Data.module_range_list);
            store.commit("setModuleMenus");
            let index = menus.value.findIndex(
              (item) => item.path === activePath.value
            );
            store.commit("setModuleIndex", index === -1 ? 0 : index);
          } else {
            const msg = res.Message ? res.Message : "获取模块权限失败！";
            ElMessage.error(msg);
          }
          isLoading.value = false;
        });
      };


      /**
       * 
       * 连接WebSocket
       * 
       * **/
      const ws = new WebSocket(process.env.VUE_APP_WEBSOCKET);
      const getConnect = () => {
        ws.onopen = function () {
          //每10秒发送一次心跳
          ws.ping = function () {
            setTimeout(() => {
              ws.send(JSON.stringify({ 'type': "ping", 'business': 'goods_make' }));
            }, 1500)
          }
        };
        ws.onclose = function () {
          console.log("websocket已断开");
          store.commit("wsClose", true);
        };
        ws.onmessage = function (messageEvent) {
          // console.log('onopen');
          const jsonobject = JSON.parse(messageEvent.data);
          store.commit("onMessage", jsonobject);
        };
      }
      const wsClose = computed(() => store.state.Config.wsClose);
      watch(wsClose, () => {
        // console.log("aaaaaa",wsClose)
        getConnect();
      });
      /** 监听路由改变 */
      watch(() => route.path, routeChange);

      const numberplateDialog = ref(null);  // 车牌录入对象
      const licensePlateRef = ref(null);  // 键盘对象
      const hideFooter = ref(true);  // 是否显示按钮
      const isAdmissionInfo = ref(false);  // 是否显示入场信息
      const last_pass_time = ref('');  // 入场时间
      const enterLicensePlate = ref('');  // 录入车牌号
      const isNoData = ref(false);  // 是否显示未查到数据页面
      const remainParkCount = ref(0);  // 空车位数量
      /**
       * 
       * 点击车牌录入
       * 
       * **/
      const onNumberplateBtn = () => {
        numberplateDialog.value.show();
        hideFooter.value = true;
        isAdmissionInfo.value = false;
        isNoData.value = false;
        SaleApi.getRemainParkCount().then((res) => {
          if (res.Code === 200) {
            remainParkCount.value = res.Data.left;
          } else {
            ElMessage.error(res.Message);
          }
        });
        nextTick(() => {
          licensePlateRef.value.serachInputRefFocus();
        })
      }
      /**
       * 
       * 点击查询按钮
       * 
       * **/
      const onQueryBtn = (data) => {
        if (!data) {
          ElMessage.error("请输入车牌");
          return false;
        }
        licensePlateRef.value.isLoading = true;
        data = data.toUpperCase();
        if (isVehicleNumber(data)) {
          SaleApi.getVehicleEntryTime({ plates: data }).then((res) => {
            if (res.Code === 200) {
              enterLicensePlate.value = data;
              last_pass_time.value = res.Data.last_pass_time;
              hideFooter.value = false;
              licensePlateRef.value.isShowKeyBoard = false;
              isAdmissionInfo.value = true;
            } else {
              licensePlateRef.value.isShowKeyBoard = false;
              isNoData.value = true;
              last_pass_time.value = '';
              ElMessage.error(res.Message);
            }
            licensePlateRef.value.isLoading = false;
          });
        } else {
          ElMessage.error("车牌号错误");
          licensePlateRef.value.isLoading = false;
        }
      }
      /**
       * 
       * 输入框为空
       * 
       * **/
      const inputEvent = (data) => {
        if (!data) {
          licensePlateRef.value.isShowKeyBoard = true;
          hideFooter.value = true;
          isAdmissionInfo.value = false;
          isNoData.value = false;
        }
      }
      /**
       * 
       * 确认录入
       * 
       * **/
      const onConfirmEntry = () => {
        numberplateDialog.value.isLoading = true;
        SaleApi.addCarCoupon({ plates: enterLicensePlate.value }).then((res) => {
          numberplateDialog.value.isLoading = false;
          if (res.Code === 200) {
            ElMessage.success("操作成功！");
            numberplateDialog.value.close();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }

      onMounted(() => {
        // 获取用户模块权限
        userInfo.realname && getUserRole();
        getConnect();
      });

      return {
        isLoading,
        isExpand,
        userInfo,
        menus,
        activePath,
        showDialog,
        handleOut,
        exit,
        skinData,
        activeTheme,
        checkSkin,
        expandChange,
        store,
        getConnect,
        numberplateDialog,
        onNumberplateBtn,
        licensePlateRef,
        onQueryBtn,
        hideFooter,
        isAdmissionInfo,
        last_pass_time,
        inputEvent,
        onConfirmEntry,
        enterLicensePlate,
        authData,
        isNoData,
        remainParkCount,
      };
    },
  };
</script>

<style lang="scss">
  #top {
    .empty-parking-space {
      margin-bottom: 15px;
      color: var(--text-third-color);

      .parking-space-title {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-color);
      }

      .parking-space-number {
        font-size: 24px;
        font-weight: bold;
        color: var(--theme-color);
      }
    }

    .el-dialog .el-dialog__footer .el-button {
      width: 160px;
    }

    .logo-container {
      min-width: 250px;
      padding: 0;
      background-color: var(--bg-color);
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 141px;
      }
    }

    .logo-container.expand {
      min-width: 150px;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--bg-color);

      >.iconfont {
        font-size: 32px;
        color: var(--text-color);
        margin-right: 15px;
        cursor: pointer;
      }

      .el-dropdown__popper {
        --el-dropdown-menu-box-shadow: var(--search-bg-color) !important;
        --el-dropdown-menuItem-hover-fill: var(--search-bg-color) !important;
        --el-dropdown-menuItem-hover-color: var(--search-bg-color) !important;
        --el-dropdown-menu-index: 10;
      }

      .active-skin {
        color: var(--theme-color) !important;
        background-color: var(--search-bg-color) !important;
      }

      .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom: 2px solid var(--theme-color);
        color: var(--theme-color) !important;
        background-color: var(--search-bg-color);
      }

      .el-menu--horizontal>.el-menu-item {
        color: var(--top-text-color);
      }

      .el-menu-item:hover {
        background-color: var(--bg-color);
      }

      .header-right {
        justify-content: flex-end;
        padding: 0 30px 0 20px;
        color: var(--text-third-color);
        font-size: 15px;
        display: flex;

        >span {
          text-align: right;
        }

        >div {
          margin: 0 26px 0 14px;
        }

        i {
          cursor: pointer;

          &:hover {
            font-weight: bold;
          }
        }
      }

      .el-menu {
        flex: 1;
        width: calc(100% - 590px);
        border-bottom: none;
        background-color: var(--bg-color);

        .el-menu-item.is-active {
          font-weight: bold;
          pointer-events: none;
          cursor: pointer;
        }
      }
    }

    .el-header {
      height: auto;
      line-height: 80px;
      padding: 0px;

      .el-menu--horizontal {
        border: none;
      }
    }

    .child-nav {
      padding: 15px 20px 0 20px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      overflow: -moz-scrollbars-none;
      background-color: var(--theme-bg-color);

      .el-button {
        min-height: 32px !important;
        height: 32px;
        line-height: 0px;
      }

      .child-btn {
        background-color: var(--theme-bg-color);
        color: var(--theme-color);
        border-color: var(--theme-color);
      }

      .active-btn {
        color: var(--text-white-color);
        background-color: var(--theme-color);
        outline: 0;
      }
    }

    .child-nav::-webkit-scrollbar {
      display: none;
    }
  }
</style>