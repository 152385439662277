const hotelChilds = [
  {
    path: "hprice",
    name: "Hprice",
    component: () => import("@/views/hotel/hprice.vue"),
    children: [
      {
        path: "everyday",
        name: "Everyday",
        component: () => import("@/views/hotel/children/everyday.vue"),
      },
      {
        path: "combo",
        name: "Combo",
        component: () => import("@/views/hotel/children/combo.vue"),
      }
    ]
  },
  {
    path: "htype",
    name: "Hype",
    component: () => import("@/views/hotel/htype.vue"),
    children: [
      {
        path: "tmanager",
        name: "Tmanager",
        component: () => import("@/views/hotel/children/tmanager.vue"),
      }
    ]
  },
  {
    path: "hconfig",
    name: "Hconfig",
    component: () => import("@/views/hotel/hconfig.vue"),
    children: [
      {
        path: "config",
        name: "Config",
        component: () => import("@/views/hotel/children/config.vue"),
      }
    ]
  },
  {
    path: "tag",
    name: "Tag",
    component: () => import("@/views/hotel/tag.vue"),
    children: [
      {
        path: "tagmanage",
        name: "Tagmanage",
        component: () => import("@/views/hotel/children/tagmanage.vue"),
      }
    ]
  },
];
export default hotelChilds;
