import axios from "./axios.js";

/** 外层框架 */
const FrameApi = {
  /** 登录 */
  login(data) {
    return axios.post("/home/login/account.api", data);
  },
  /** 获取登录页面信息 */
  getSystemInfo(data) {
    return axios.post("/home/baseinfo/get_system_info.api", data);
  },
  /** 获取当前用户信息 */
  getUserInfo(data) {
    return axios.post("/home/home/get_user_info.api", data);
  },
  /** 获取用户权限信息 */
  getUserRole(data) {
    return axios.post("/home/home/get_user_role.api", data);
  },
  /** 编辑密码 */
  editPassword(data) {
    return axios.post("/home/home/change_password.api", data);
  },
  qywxInfo(data) {
    return axios.post("/home/qy_login/get_login_before.api", data);
  },
  getUserInfoByqy(data) {
    return axios.post("/home/qy_login/auth_login.api", data);
  },
  qySdk(data) {
    return axios.post("/home/qy_login/get_qy_jssdk.api", data);
  },
};

/** 基础数据 */
const BasicApi = {
  /** 公共接口 */
  /** 获取商户号支付配置 */
  accountOptions(data) {
    return axios.post("/basic_data/common/get_mch_api.api", data);
  },
  getMemberTicket(data) {
    return axios.post("/scenic_spot/common/get_member_for_ticket.api", data);
  },
  userOptions(data) {
    return axios.post("/basic_data/common/get_user_api.api", data);
  },
  /** 获取七牛云上传参数 */
  getQiniuInfo(data) {
    return axios.post("/basic_data/common/qyn_up_token.api", data);
  },
  /** 户型标签 */
  roomLable(data) {
    return axios.post("/basic_data/common/room_lable_select.api", data);
  },
  /** 项目列表 */
  projectSelect(data) {
    return axios.post("/basic_data/common/project_select.api", data);
  },
  /** 获取部门 */
  getDuty(data) {
    return axios.post("/basic_data/common/get_duty_api.api", data);
  },
  /** 项目获取园区 */
  getScenicByProject(data) {
    return axios.post("/basic_data/common/get_scenic_api.api", data);
  },
  /** 根据项目选门店 */
  getMerchantByProject(data) {
    return axios.post("/basic_data/common/get_poly_merchant_api.api", data);
  },
  /** 园区获取景点 */
  getSpotByScenic(data) {
    return axios.post("/basic_data/common/get_scenic_spot_api.api", data);
  },
  /** 获取可供门票绑定的园区/景点列表 */
  rolebindList(data) {
    return axios.post("/basic_data/common/get_scenic_rolebind_list.api", data);
  },
  /** 获取餐厅 */
  getDinnerList(data) {
    return axios.post("/basic_data/common/get_dinner_api.api", data);
  },
  /** 获取酒店 */
  getHotelByProject(data) {
    return axios.post("/basic_data/common/get_hotel_api.api", data);
  },
  /** 获取酒店房型 */
  getTypeByHotel(data) {
    return axios.post("/basic_data/common/get_hotel_type_api.api", data);
  },
  /** 获取酒店房型套餐 */
  getComboByHotel(data) {
    return axios.post("/basic_data/common/get_hotel_package_api.api", data);
  },
  /** 获取角色 */
  getRole(data) {
    return axios.post("/basic_data/common/get_role_api.api", data);
  },
  /** 获取权限 */
  getNode(data) {
    return axios.post("/basic_data/common/get_node_api.api", data);
  },
  /** 获取用户已选择权限 */
  getSelectedNode(data) {
    return axios.post("/basic_data/common/get_user_node_data.api", data);
  },

  /** 获取餐厅 */
  getDinner(data) {
    return axios.post("/basic_data/common/get_dinner_api.api", data);
  },
  /** 获取权限模块 */
  getModule(data) {
    return axios.post("/basic_data/common/get_module_api.api", data);
  },
  /** 获取小程序 */
  getMini(data) {
    return axios.post("/basic_data/common/get_mini_api.api", data);
  },
  /** 获取社群 */
  getCommunity(data) {
    return axios.post("/basic_data/common/get_community_api.api", data);
  },
  /** 来源平台 */
  getPlatformOptions(data) {
    return axios.post("/basic_data/common/get_member_plat_api.api", data);
  },

  /** 获取小程序配置接口 */
  getMiniConfig(data) {
    return axios.post(
      "/basic_data/common/get_mini_program_config_api.api",
      data
    );
  },
  /** 获取小程序资源应用范围 */
  getMiniSource(data) {
    return axios.post("/basic_data/common/get_mini_source_scope_api.api", data);
  },
  /** 获取用户协议 */
  getAgreement(data) {
    return axios.post("/basic_data/mini_program/get_agreement.api", data);
  },
  /** 设置协议 */
  editAgreement(data) {
    return axios.post("/basic_data/mini_program/edit_agreement.api", data);
  },
  /** 每日房价-批量调价-获取价格范围 */
  getHotelDailyPrice(data) {
    return axios.post("/basic_data/common/get_hotel_daily_price_api.api", data);
  },
  /** 退费规则 */
  getRefundRules(data) {
    return axios.post("/basic_data/common/refund_rule_select.api", data);
  },

  /** 人员管理 */
  /** 人员修改状态 */
  setUserStatus(data) {
    return axios.post("/basic_data/user/update_status.api", data);
  },
  /** 人员新增 */
  userAdd(data) {
    return axios.post("/basic_data/user/store.api", data);
  },
  /** 人员编辑 */
  userUpdate(data) {
    return axios.post("/basic_data/user/update.api", data);
  },
  /** 人员修改密码 */
  resetPassword(data) {
    return axios.post("/basic_data/user/reset_password.api", data);
  },
  /** 编辑用户模块权限 */
  updateModule(data) {
    return axios.post("/basic_data/user/update_node_module.api", data);
  },

  /** 角色管理 */
  /** 获取分页角色列表 */
  roleList(data) {
    return axios.post("/basic_data/role/list.api", data);
  },
  /** 角色新增 */
  roleAdd(data) {
    return axios.post("/basic_data/role/store.api", data);
  },
  /** 角色编辑 */
  roleUpdate(data) {
    return axios.post("/basic_data/role/update.api", data);
  },
  /** 角色编辑权限 */
  roleUpdateRole(data) {
    return axios.post("/basic_data/role/update_node_by_role.api", data);
  },
  /** 角色编辑权限范围 */
  roleUpdateRoleRange(data) {
    return axios.post("/basic_data/user/update_role_range.api", data);
  },
  /** 角色详情 */
  roleInfo(data) {
    return axios.post("/basic_data/role/info.api", data);
  },

  /** 组织信息 */
  /** 获取组织树形结构 */
  getOrgTree(data) {
    return axios.post("/basic_data/org/get_org_tree.api", data);
  },
  /** 新增组织 */
  orgAdd(data) {
    return axios.post("/basic_data/org/store.api", data);
  },
  /** 更新组织 */
  orgUpdate(data) {
    return axios.post("/basic_data/org/update.api", data);
  },
  /** 获取组织下用户信息 */
  getUserByOrg(data) {
    return axios.post("/basic_data/org/get_user_by_org.api", data);
  },

  /** 资源管理 */
  /** 园区管理 */
  /** 获取园区列表 */
  scenicList(data) {
    return axios.post("/basic_data/resource/scenic_list.api", data);
  },
  /** 获取景点列表 */
  spotList(data) {
    return axios.post("/basic_data/resource/spot_list.api", data);
  },
  /** 园区入口列表 */
  entranceList(data) {
    return axios.post("/basic_data/resource/entrance_list.api", data);
  },

  /** 新增园区 */
  scenicAdd(data) {
    return axios.post("/basic_data/resource/store_scenic.api", data);
  },
  // 园区入口详情
  entranceDetail(data) {
    return axios.post("/basic_data/resource/get_entrance_info.api", data);
  },
  setSpotStatus(data) {
    return axios.post("/basic_data/resource/update_spot_status.api", data);
  },
  /** 编辑园区 */
  scenicUpdate(data) {
    return axios.post("/basic_data/resource/update_scenic.api", data);
  },
  /** 新增景点 */
  spotAdd(data) {
    return axios.post("/basic_data/resource/store_spot.api", data);
  },
  /** 新增入口 */
  entranceAdd(data) {
    return axios.post("/basic_data/resource/store_entrance.api", data);
  },
  /** 编辑入口 */
  entranceEdit(data) {
    return axios.post("/basic_data/resource/update_entrance.api", data);
  },
  /** 修改入口状态 */
  setEntranceStatus(data) {
    return axios.post("/basic_data/resource/update_entrance_status.api", data);
  },

  /** 酒店列表 */
  /** 获取酒店列表 */
  hotelList(data) {
    return axios.post("/basic_data/resource/hotel_list.api", data);
  },
  /** 新增酒店 */
  hotelAdd(data) {
    return axios.post("/basic_data/resource/store_hotel.api", data);
  },
  /** 编辑酒店 */
  hotelUpdate(data) {
    return axios.post("/basic_data/resource/update_hotel.api", data);
  },

  /** 闸机管理 */
  /** 获取闸机列表 */
  gateList(data) {
    return axios.post("/basic_data/resource/gate_list.api", data);
  },
  /** 新增闸机 */
  gateAdd(data) {
    return axios.post("/basic_data/resource/store_gate.api", data);
  },
  /** 更新闸机状态 */
  gateStatusUpdate(data) {
    return axios.post("/basic_data/resource/update_gate_status.api", data);
  },
  /** 更新闸机状态 */
  getEntranceByScenic(data) {
    return axios.post("/basic_data/common/get_scenic_entrance_api.api", data);
  },

  /**
   *监控管理
   * 资源类型type 1=分组 2=设备
   */

  // 获取监控分组列表   获取监控分组列表
  getMonitorList(data) {
    return axios.post("/basic_data/resource/monitor_list.api", data);
  },

  // 新增监控分组
  addMonitor(data) {
    return axios.post("/basic_data/resource/store_monitor.api", data);
  },
  //编辑监控分组
  updateMonitor(data) {
    return axios.post("/basic_data/resource/update_monitor.api", data);
  },
  // 删除监控分组
  delMonitor(data) {
    return axios.post("/basic_data/resource/del_monitor.api", data);
  },
  // 获取海康视频取流时需要的认证信息
  getHikEzvizAccount(data) {
    return axios.post("/basic_data/common/get_hik_ezviz_account.api", data);
  },
  /**
   * IOT 物联网通信
   */
  // 获取产品列表
  iotList(data) {
    return axios.post("/basic_data/resource/iot_list.api", data);
  },
  //删除产品信息
  iotDel(data) {
    return axios.post("/basic_data/resource/del_iot.api", data);
  },
  //编辑产品信息
  iotUpdate(data) {
    return axios.post("/basic_data/resource/update_iot.api", data);
  },
  // 新增产品信息
  iotStore(data) {
    return axios.post("/basic_data/resource/store_iot.api", data);
  },

  /** 餐厅管理 */
  /** 获取餐厅列表 */
  dinnerList(data) {
    return axios.post("/basic_data/resource/dinner_list.api", data);
  },
  /** 餐厅新增 */
  dinnerAdd(data) {
    return axios.post("/basic_data/resource/store_dinner.api", data);
  },
  /** 餐厅编辑 */
  dinnerUpdate(data) {
    return axios.post("/basic_data/resource/update_dinner.api", data);
  },

  /** 小程序管理 */
  /** 小程序列表 */
  miniList(data) {
    return axios.post("/basic_data/mini_program/mp_list.api", data);
  },
  /** 小程序资源上线明细设置 */
  miniDetailSet(data) {
    return axios.post("/basic_data/mini_program/source_detail.api", data);
  },
  /** 获取小程序配置信息 */
  gitMiniConfig(data) {
    return axios.post("/basic_data/mini_program/get_mp_config_list.api", data);
  },
  /** 设置小程序配置信息 */
  setMiniConfig(data) {
    return axios.post("/basic_data/mini_program/set_mp_config.api", data);
  },
  /** 删除小程序配置信息 */
  delMiniConfig(data) {
    return axios.post("/basic_data/mini_program/del_mp_config.api", data);
  },
  /** 所有园区 */
  getAllScenicData(data) {
    return axios.post("/basic_data/common/get_scenic_all.api", data);
  },
  /** 所有酒店 */
  getAllHotelData(data) {
    return axios.post("/basic_data/common/get_hotel_all.api", data);
  },
  /** 所有餐厅 */
  getAllDinnerData(data) {
    return axios.post("/basic_data/common/get_dinner_all.api", data);
  },
  /** 所有支付门店 */
  getAllPolyMerchantData(data) {
    return axios.post("/basic_data/common/get_poly_merchant_all.api", data);
  },
  /** 所有活动 */
  getAllActiveData(data) {
    return axios.post("/basic_data/common/get_active_all.api", data);
  },
  /** 所有门票 */
  getAllTicketData(data) {
    return axios.post("/basic_data/common/get_ticket_all.api", data);
  },

  /** 订单状态 */
  orderStatusOptions(data) {
    return axios.post(
      "/basic_data/common/get_order_hotel_status_api.api",
      data
    );
  },
  /** 订单来源 */
  orderSourceOptions(data) {
    return axios.post("/basic_data/common/get_order_source_api.api", data);
  },
  /** 订单来源 */
  paywayOptions(data) {
    return axios.post("/basic_data/common/get_payway_all.api", data);
  },
  /** 代理商 */
  agentOptions(data) {
    return axios.post("/basic_data/common/get_agent_all.api", data);
  },

  /** 资源管理-门票管理 */
  /** 修改门票排序 */
  modifyTicketSorting(data) {
    return axios.post("/basic_data/ticket/set_sort.api", data);
  },
};

/** 餐厅管理  */
const DinnerApi = {
  /** 获取分页餐厅列表 */
  diningList(data) {
    return axios.post("/restaurant/dinner/list.api", data);
  },
  /** 餐厅修改状态 */
  dinStatusUpdate(data) {
    return axios.post("/restaurant/dinner/update_status.api", data);
  },
  /** 餐厅新增 */
  diningAdd(data) {
    return axios.post("/restaurant/dinner/store.api", data);
  },
  /** 餐厅编辑 */
  diningUpdate(data) {
    return axios.post("/restaurant/dinner/update.api", data);
  },
  /** 餐厅详情 */
  diningInfo(data) {
    return axios.post("/restaurant/dinner/info.api", data);
  },
  /** 新增餐厅图册文件 */
  dinPhotoAdd(data) {
    return axios.post("/restaurant/dinner/add_dinner_photo.api", data);
  },
  /** 删除餐厅图册文件 */
  dinPhotoDel(data) {
    return axios.post("/restaurant/dinner/del_dinner_photo.api", data);
  },
  /** 获取餐厅图册文件 */
  getDinnerPhoto(data) {
    return axios.post("/restaurant/dinner/get_dinner_photo.api", data);
  },
};

/** 酒店管理 */
const HotelApi = {
  /** 获取酒店列表 */
  getHotelList(data) {
    return axios.post("/hotel/hotel/get_hotel_list.api", data);
  },
  /** 编辑酒店 */
  hotelEdit(data) {
    return axios.post("/hotel/hotel/edit_hotel.api", data);
  },
  /** 修改酒店状态 */
  setHotelStatus(data) {
    return axios.post("/hotel/hotel/change_hotel_status.api", data);
  },
  /** 获取酒店图册 */
  getHotelPhoto(data) {
    return axios.post("/hotel/hotel/get_hotel_photo.api", data);
  },
  /** 新增酒店图册文件 */
  addHotelPhoto(data) {
    return axios.post("/hotel/hotel/add_hotel_photo.api", data);
  },
  /** 删除酒店图册文件 */
  delHotelPhoto(data) {
    return axios.post("/hotel/hotel/del_hotel_photo.api", data);
  },
  /** 设置酒店核销人员 */
  setHotelChecker(data) {
    return axios.post("/hotel/hotel/set_check_user.api", data);
  },
  /** 设置酒店核销人员 */
  getHotelInfo(data) {
    return axios.post("/hotel/hotel/get_hotel_info.api", data);
  },
  /** 取消酒店核销人员 */
  deleteHotelChecker(data) {
    return axios.post("/hotel/hotel/remove_check_user.api", data);
  },
  /** 获取酒店分成比例 */
  getHotelAccount(data) {
    return axios.post("/hotel/hotel/get_hotel_divide.api", data);
  },
  /** 设置酒店分成比例 */
  setHotelAccount(data) {
    return axios.post("/hotel/hotel/set_hotel_divide.api", data);
  },

  /** 标签管理 */
  /** 获取标签列表 */
  getLableList(data) {
    return axios.post("/hotel/room_lable/get_lable_list.api", data);
  },
  /** 获取标签详情 */
  getLableInfo(data) {
    return axios.post("/hotel/room_lable/get_lable_info.api", data);
  },
  /** 新增标签 */
  addLable(data) {
    return axios.post("/hotel/room_lable/add_lable.api", data);
  },
  /** 修改标签 */
  editLable(data) {
    return axios.post("/hotel/room_lable/edit_lable.api", data);
  },
  /** 修改标签状态 */
  setLableStatus(data) {
    return axios.post("/hotel/room_lable/change_lable_status.api", data);
  },

  /** 户型管理 */
  /** 获取户型列表 */
  roomTypeList(data) {
    return axios.post("/hotel/room_type/get_room_type_list.api", data);
  },
  /** 获取户型详情 */
  roomTypeInfo(data) {
    return axios.post("/hotel/room_type/get_room_type_info.api", data);
  },
  /** 新增户型 */
  addRoomType(data) {
    return axios.post("/hotel/room_type/add_room_type.api", data);
  },
  /** 修改户型 */
  editRoomType(data) {
    return axios.post("/hotel/room_type/edit_room_type.api", data);
  },
  /** 修改户型状态 */
  setRoomTypeStatus(data) {
    return axios.post("/hotel/room_type/change_room_type_status.api", data);
  },
  /** 获取户型图册 */
  getRoomTypePhoto(data) {
    return axios.post("/hotel/room_type/get_room_type_photo.api", data);
  },
  /** 新增户型图册文件 */
  addRoomTypePhoto(data) {
    return axios.post("/hotel/room_type/add_room_type_photo.api", data);
  },
  /** 删除户型图册文件 */
  delRoomTypePhoto(data) {
    return axios.post("/hotel/room_type/del_room_type_photo.api", data);
  },

  /** 房价管理 */

  /** 套餐管理 */
  /** 获取套餐列表 */
  hotelPackageList(data) {
    return axios.post("/hotel/hotel_package/get_hotel_package_list.api", data);
  },
  /** 新增套餐 */
  addHotelPackage(data) {
    return axios.post("/hotel/hotel_package/add_hotel_package.api", data);
  },
  /** 修改套餐 */
  editHotelPackage(data) {
    return axios.post("/hotel/hotel_package/edit_hotel_package.api", data);
  },
  /** 修改套餐状态 */
  setHotelPackageStatus(data) {
    return axios.post(
      "/hotel/hotel_package/change_hotel_package_status.api",
      data
    );
  },
  /** 发布套餐 */
  publishHotelPackage(data) {
    return axios.post("/hotel/hotel_package/publish_hotel_package.api", data);
  },

  /** 每日房价 */
  /** 获取某天详情 */
  hotelDailyInfo(data) {
    return axios.post("/hotel/hotel_daily/get_hotel_daily_info.api", data);
  },
  /** 修改某天房型数量 */
  editHotelDailyNumber(data) {
    return axios.post("/hotel/hotel_daily/edit_hotel_daily_number.api", data);
  },
  /** 修改某天房型价格 */
  editHotelDailyPrice(data) {
    return axios.post("/hotel/hotel_daily/edit_hotel_daily_price.api", data);
  },
  /** 修改某天套餐状态 */
  setHotelDailyStatus(data) {
    return axios.post("/hotel/hotel_daily/change_hotel_daily_status.api", data);
  },
  /** 批量修改某天套餐价格 */
  batchHotelDailyPrice(data) {
    return axios.post("/hotel/hotel_daily/batch_hotel_daily_price.api", data);
  },
  /** 获取调价记录 */
  hotelDailyLog(data) {
    return axios.post("/hotel/hotel_daily/get_hotel_daily_log.api", data);
  },
  /** 获取酒店最大延期天数 */
  getPostpone(data) {
    return axios.post("/basic_data/common/get_max_ext_day.api", data);
  },
  /** 设置酒店最大延期天数 */
  setPostpone(data) {
    return axios.post("/hotel/hotel/set_hotel_ext_day.api", data);
  },
};

/** 园区管理 */
const ScenicApi = {
  /** 获取园区列表 */
  semanageList(data) {
    return axios.post("/basic_data/scenic/list.api", data);
  },
  /** 获取园区详情 */
  semanageInfo(data) {
    return axios.post("/basic_data/scenic/info.api", data);
  },
  /** 编辑园区 */
  semanageEdit(data) {
    return axios.post("/basic_data/scenic/edit.api", data);
  },
  /** 修改园区状态 */
  setSestatus(data) {
    return axios.post("/basic_data/scenic/set_status.api", data);
  },
  /** 修改园区游玩指引 */
  playGuide(data) {
    return axios.post("/basic_data/scenic/play_guide.api", data);
  },
  /** 获取园区图册 */
  getPhotos(data) {
    return axios.post("/basic_data/scenic/get_photo.api", data);
  },
  /** 新增园区 */
  scenicAdd(data) {
    return axios.post("/basic_data/resource/store_scenic.api", data);
  },
  /** 新增园区图片 */
  insertPhoto(data) {
    return axios.post("/basic_data/scenic/insert_photo.api", data);
  },
  /** 删除园区图片 */
  delPhoto(data) {
    return axios.post("/basic_data/scenic/del_photo.api", data);
  },
  /** 设置核验员 */
  setScenicChecker(data) {
    return axios.post("/basic_data/scenic/set_check_user.api", data);
  },
  /** 删除核验员 */
  deleteSceniclChecker(data) {
    return axios.post("/basic_data/scenic/remove_check_user.api", data);
  },

  /** 园区入口 */
  /** 获取入口列表 */
  sentranceList(data) {
    return axios.post("/scenic_spot/entrance/list.api", data);
  },
  /** 编辑入口 */
  sentranceEdit(data) {
    return axios.post("/scenic_spot/entrance/edit.api", data);
  },
  /** 修改入口状态 */
  setEntranceStatus(data) {
    return axios.post("/scenic_spot/entrance/set_status.api", data);
  },

  /** 会员卡 */
  /** 会员卡列表 */
  getMembershipCardLists(data) {
    return axios.post("/scenic_spot/member_card/list.api", data);
  },
  /** 激活前获取信息 */
  getBeforeRegisterInfo(data) {
    return axios.post("/scenic_spot/member_card/before_register_info.api", data);
  },
  /** 激活会员卡 */
  activateMembershipCard(data) {
    return axios.post("/scenic_spot/member_card/register_member_card.api", data);
  },
  /** 录入前获取信息 */
  getBeforeCardReceiveInfo(data) {
    return axios.post("/scenic_spot/member_card/before_card_receive_info.api", data);
  },
  /** 通行证录入 */
  enterCardReceive(data) {
    return axios.post("/scenic_spot/member_card/card_receive.api", data);
  },
  /** 通行证录入 */
  quickEntry(data) {
    return axios.post("/scenic_spot/member_card/card_receive_indie.api", data);
  },
  /** 会员卡使用记录列表 */
  getMemberCardLogList(data) {
    return axios.post("/scenic_spot/member_card/member_card_log_list.api", data);
  },
  /** 撤销激活会员卡 */
  unregisterMemberCard(data) {
    return axios.post("/scenic_spot/member_card/unregister_member_card.api", data);
  },
  /** 撤销会员卡使用记录 */
  memberCardLogRevoke(data) {
    return axios.post("/scenic_spot/member_card/member_card_log_revoke.api", data);
  },

  /** 核销 */
  /** 核销类型列表 */
  getCheckTypeList(data) {
    return axios.post("/scenic_spot/check/check_type_list.api", data);
  },
  /** 列表 */
  getCheckLists(data) {
    return axios.post("/scenic_spot/check/list.api", data);
  },
  /** 核销 */
  confirmWriteOff(data) {
    return axios.post("/scenic_spot/check/check.api", data);
  },
  /** 已核销明细列表 */
  getCheckedList(data) {
    return axios.post("/scenic_spot/check/checked_list.api", data);
  },

  /** 后厨管理 */
  /** 获取后厨列表 */
  getMakeLists(data) {
    return axios.post("/scenic_spot/goods_make/list.api", data);
  },
  /** 状态变更 */
  changeMakeStatus(data) {
    return axios.post("/scenic_spot/goods_make/set_status.api", data);
  },
  /** 打印数据 */
  getGoodsMakeprint(data) {
    return axios.post("/scenic_spot/goods_make/goods_make_print.api", data);
  },
  /** 获取后厨制作屏幕列表 */
  goodsMakeScreenList(data) {
    return axios.post("/scenic_spot/common/goods_make_screen_list.api", data);
  },


  /** 景点管理 */
  /** 获取景点列表 */
  spotEntranceList(data) {
    return axios.post("/scenic_spot/spot/list.api", data);
  },
  /** 获取景点详情 */
  spotEntranceInfo(data) {
    return axios.post("/scenic_spot/spot/info.api", data);
  },
  /** 编辑景点 */
  spotEntranceEdit(data) {
    return axios.post("/scenic_spot/spot/edit.api", data);
  },
  /** 修改景点状态 */
  setSpotStatus(data) {
    return axios.post("/scenic_spot/spot/set_status.api", data);
  },
  /** 设置景点核验员 */
  setSpotChecker(data) {
    return axios.post("/scenic_spot/spot/set_check_user.api", data);
  },
  /** 删除景点核验员 */
  deleteSpotChecker(data) {
    return axios.post("/scenic_spot/spot/remove_check_user.api", data);
  },

  /** 门票管理 */
  /** 新增门票信息*/
  ticketInsert(data) {
    return axios.post("/basic_data/ticket/insert.api", data);
  },
  /** 编辑门票信息 */
  ticketEdit(data) {
    return axios.post("/basic_data/ticket/edit.api", data);
  },
  /** 获取门票列表 */
  ticketList(data) {
    return axios.post("/basic_data/ticket/list.api", data);
  },
  /** 获取门票详情 */
  ticketInfo(data) {
    return axios.post("/scenic_spot/ticket/info.api", data);
  },
  /** 获取门票优惠券 */
  getGiftList(data) {
    return axios.post("/scenic_spot/common/get_gift_list.api", data);
  },
  /** 获取门票优惠券 */
  getTicketGift(data) {
    return axios.post("/basic_data/ticket/get_ticket_gift.api", data);
  },
  /** 编辑门票优惠券 */
  setticketgift(data) {
    return axios.post("/basic_data/ticket/set_ticket_gift.api", data);
  },
  /** 获取门票类型 */
  ticketType(data) {
    return axios.post("/scenic_spot/common/get_type.api", data);
  },
  /** 获取门票类型 -会员卡列表-线下售票列表等筛选 */
  getNewTicketType(data) {
    return axios.post("/scenic_spot/common/get_new_type.api", data);
  },
  /** 通过类型获取资源名称-用于筛选 */
  getSourceNameByType(data) {
    return axios.post("/scenic_spot/common/get_source_name_by_type.api", data);
  },
  /** 获取门票类型 */
  getMemberCardStatus(data) {
    return axios.post("/vip_services/common/get_member_card_status.api", data);
  },
  /** 修改门票状态 */
  ticketStatusEdit(data) {
    return axios.post("/basic_data/ticket/set_status.api", data);
  },
  /** 修改门票原住民通道状态 */
  localStatusEdit(data) {
    return axios.post("/basic_data/ticket/set_local_pass.api", data);
  },
  /** 设置关联小程序状态 */
  miniStatusEdit(data) {
    return axios.post("/basic_data/ticket/set_mini_status.api", data);
  },
  /** 修改门票生日状态 */
  setBirthday(data) {
    return axios.post("/basic_data/ticket/set_birthday.api", data);
  },
  /** 获取可售门票列表 */
  sellList(data) {
    return axios.post("/scenic_spot/ticket/sell_list.api", data);
  },
  /** 线下售票 */
  sellTicket(data) {
    return axios.post("/scenic_spot/ticket/sell_ticket.api", data);
  },
  /** 团体售票 */
  sellGroupTicket(data) {
    return axios.post("/basic_data/ticket/sell_group_ticket.api", data);
  },
  /** 获取支付订单信息 */
  orderInfo(data) {
    return axios.post("/order/order/get_pay_order_info.api", data);
  },
  /** 获取门票订单相关会员卡信息 */
  getMemberCardByOrder(data) {
    return axios.post("/order/order/get_member_card_by_order.api", data);
  },
  /** 柜台付款码支付 */
  barCodeInfo(data) {
    return axios.post("/order/pay/payment_code.api", data);
  },
  // 支付方式
  getOrderType(data) {
    return axios.post("/scenic_spot/common/get_pay_way_for_offline.api", data);
  },
  // 可用AR账户列表
  accountOptions(data) {
    return axios.post("/scenic_spot/common/get_ar_account_list.api", data);
  },
  // 获取门票分成比例
  getTicketAccount(data) {
    return axios.post("/scenic_spot/ticket/get_ticket_divide.api", data);
  },
  // 设置门票分成比例
  setTicketAccount(data) {
    return axios.post("/scenic_spot/ticket/set_ticket_divide.api", data);
  },
  // 代理商状态
  setAgentStatus(data) {
    return axios.post("/scenic_spot/ticket/set_agent_status.api", data);
  },
  // 获取门票代理配置列表
  getAgentList(data) {
    return axios.post("/scenic_spot/ticket/agent_ticket_list.api", data);
  },
  // 已配置代理商状态
  setConfigAgentStatus(data) {
    return axios.post(
      "/scenic_spot/ticket/change_agent_ticket_status.api",
      data
    );
  },
  // 所有代理商
  agentOptions(data) {
    return axios.post("/scenic_spot/common/get_agent_for_ticket.api", data);
  },
  // 所有代理商
  addAgentConfig(data) {
    return axios.post("/scenic_spot/ticket/add_agent_ticket.api", data);
  },

  /** 声音博物馆 **/
  /** 文件管理 **/
  // 分类列表
  getClasses(data) {
    return axios.post("/scenic_spot/sound_museum/get_classes.api", data);
  },
  // 添加分类
  addClasses(data) {
    return axios.post("/scenic_spot/sound_museum/add_classes.api", data);
  },
  // 编辑分类
  editClasses(data) {
    return axios.post("/scenic_spot/sound_museum/edit_classes.api", data);
  },
  // 添加文件
  addSoundFile(data) {
    return axios.post("/scenic_spot/sound_museum/add_sound_file.api", data);
  },
  // 编辑文件
  editSoundFile(data) {
    return axios.post("/scenic_spot/sound_museum/edit_sound_file.api", data);
  },
  // 文件列表
  getSoundFileLists(data) {
    return axios.post("/scenic_spot/sound_museum/get_sound_file.api", data);
  },
  // 资源文件状态
  updateStatus(data) {
    return axios.post("/scenic_spot/sound_museum/update_sound_file.api", data);
  },
  /** 点位管理 **/
  // 点位列表
  getSoundMuseum(data) {
    return axios.post("/scenic_spot/sound_museum/get_sound_museum.api", data);
  },
  // 添加点位
  addSoundMuseum(data) {
    return axios.post("/scenic_spot/sound_museum/add_sound_museum.api", data);
  },
  // 编辑点位
  editSoundMuseum(data) {
    return axios.post("/scenic_spot/sound_museum/edit_sound_museum.api", data);
  },
  // 编辑点位
  updateSoundMuseumStatus(data) {
    return axios.post(
      "/scenic_spot/sound_museum/update_sound_museum.api",
      data
    );
  },
  // 点位数据导入
  importSoundMuseum(data) {
    return axios.post("/scenic_spot/sound_museum/import.api", data);
  },

  /** 通行证 */
  // 通行证列表
  getPassportList(data) {
    return axios.post("/scenic_spot/member_card_receive/list.api", data);
  },
  // 通行证重新注册下发
  passportReregistration(data) {
    return axios.post("/scenic_spot/member_card_receive/register.api", data);
  },

  /** 会员卡 通行证录入 */
  cardReceive(data) {
    return axios.post("/scenic_spot/member_card/card_receive.api", data);
  },
  /** 会员新增 */
  memberAddition(data) {
    return axios.post("/scenic_spot/common/member_store.api", data);
  },
};

/** 会员服务 */
const VipApi = {
  /** 活动列表 */
  getCtiveCodeLists(data) {
    return axios.post("/vip_services/active_code/list.api", data);
  },
  /** 获得活动类型数据 */
  getActiveTypes(data) {
    return axios.post("/vip_services/active_code/activetype.api", data);
  },
  /** 新增活动 */
  addInsertActive(data) {
    return axios.post("/vip_services/active_code/insert.api", data);
  },
  /** 编辑活动 */
  editInsertActive(data) {
    return axios.post("/vip_services/active_code/edit.api", data);
  },
  /** 修改状态 */
  changeActiveStatus(data) {
    return axios.post("/vip_services/active_code/change_status.api", data);
  },
  /** 活动码明细列表 */
  getActiveDetails(data) {
    return axios.post("/vip_services/active_code/code_list.api", data);
  },
  /** 活动码明细列表 */
  getExportCode(data) {
    return axios.post("/vip_services/active_code/export_code.api", data);
  },
  /** 获取优惠券下发类型列表 */
  getGiftSendTypeLists(data) {
    return axios.post("/vip_services/common/get_gift_send_type_list.api", data);
  },
  /** 获取商品列表 */
  getGoodsLists(data) {
    return axios.post("/vip_services/common/get_goods_list.api", data);
  },
  /** 获取优惠券列表 */
  getCouponList(data) {
    return axios.post("/vip_services/common/get_gift_list.api", data);
  },
  /** 会员优惠券下发 */
  generateMemberGift(data) {
    return axios.post("/vip_services/member/generate_member_gift.api", data);
  },
  /** 会员优惠券撤销-删除 */
  revokeMemberGift(data) {
    return axios.post("/vip_services/member/revoke_member_gift.api", data);
  },






  /** 会员列表 */
  memberList(data) {
    return axios.post("/vip_services/member/list.api", data);
  },
  /** 会员新增 */
  memberAdd(data) {
    return axios.post("/vip_services/member/store.api", data);
  },
  /** 会员编辑 */
  memberUpdate(data) {
    return axios.post("/vip_services/member/update.api", data);
  },
  /** 社群列表 */
  communityList(data) {
    return axios.post("/vip_services/community/list.api", data);
  },
  /** 社群下人员列表 */
  communityMembers(data) {
    return axios.post("/vip_services/community/get_member.api", data);
  },
  /** 社群新增 */
  communityAdd(data) {
    return axios.post("/vip_services/community/store.api", data);
  },
  /** 社群编辑 */
  communityUpdate(data) {
    return axios.post("/vip_services/community/update.api", data);
  },
  /** 社群编辑状态 */
  setCommunityStatus(data) {
    return axios.post("/vip_services/community/update_status.api", data);
  },
  /** 社群编辑会长 */
  setPresident(data) {
    return axios.post("/vip_services/community/set_president.api", data);
  },
  /** 所有社长 */
  getPresidentOptions(data) {
    return axios.post("/basic_data/common/get_president_api.api", data);
  },
  /** 会员活动列表 */
  vipActivityList(data) {
    return axios.post("/vip_services/active/list.api", data);
  },
  /** 会员活动类型列表 */
  vipActivityType(data) {
    return axios.post("/vip_services/common/get_active_type_list.api", data);
  },
  /** 获取活动所需资源列表 */
  vipActivityBind(data) {
    return axios.post("/vip_services/common/get_active_bind_list.api", data);
  },
  /** 新增会员活动 */
  addVipActivity(data) {
    return axios.post("/vip_services/active/insert.api", data);
  },
  /** 编辑会员活动 */
  editVipActivity(data) {
    return axios.post("/vip_services/active/edit.api", data);
  },
  /** 会员活动详情 */
  vipActivityDetail(data) {
    return axios.post("/vip_services/active/info.api", data);
  },
  /** 会员活动编辑状态 */
  setActivityStatus(data) {
    return axios.post("/vip_services/active/change_status.api", data);
  },
  /** 获取图册 */
  getPhotos(data) {
    return axios.post("/vip_services/active/files.api", data);
  },
  /** 新增图册 */
  addPhotos(data) {
    return axios.post("/vip_services/active/add_files.api", data);
  },
  /** 删除图册 */
  delPhotos(data) {
    return axios.post("/vip_services/active/del_files.api", data);
  },
  /** 报名列表 */
  registerList(data) {
    return axios.post("/vip_services/active/register_list.api", data);
  },
  /** AR列表 */
  arList(data) {
    return axios.post("/vip_services/ar/account/list.api", data);
  },
  /** 账户类型 */
  accountTypeOptions(data) {
    return axios.post(
      "/vip_services/common/get_ar_account_type_list.api",
      data
    );
  },
  /** 账户状态 */
  accountStatusOptions(data) {
    return axios.post(
      "/vip_services/common/get_ar_account_status_list.api",
      data
    );
  },
  /** 修改ar状态 */
  setArStatus(data) {
    return axios.post("/vip_services/ar/account/change_status.api", data);
  },
  /** 新增账户 */
  addAr(data) {
    return axios.post("/vip_services/ar/account/insert.api", data);
  },
  /** 编辑账户 */
  editAr(data) {
    return axios.post("/vip_services/ar/account/edit.api", data);
  },
  /** 编辑账户 */
  accountDetail(data) {
    return axios.post("/vip_services/ar/check/list.api", data);
  },
  /** 获取AR账户 核销类型列表 */ writeOffOptions(data) {
    return axios.post(
      "/vip_services/common/get_ar_account_check_way_list.api",
      data
    );
  },
  /** 核销订单 */
  checkOrder(data) {
    return axios.post("/vip_services/ar/check/check_order.api", data);
  },
  /** 撤销核销订单 */
  revokeCheckOrder(data) {
    return axios.post("/vip_services/ar/check/revoke_check_order.api", data);
  },
  /** 会员 优惠券列表 */
  getMemberGiftLists(data) {
    return axios.post("/vip_services/member/member_gift_list.api", data);
  },

  /** 会员卡 */
  /** 获取会员卡列表 */
  getMemberCardLists(data) {
    return axios.post("/vip_services/member_card/list.api", data);
  },
  /** 会员卡新增 */
  addMemberCard(data) {
    return axios.post("/vip_services/member_card/store.api", data);
  },
  /** 会员卡编辑 */
  updateMemberCard(data) {
    return axios.post("/vip_services/member_card/update.api", data);
  },
  /** 获取优惠券类型列表 */
  getGiftTypeLists(data) {
    return axios.post("/vip_services/common/get_gift_type_list.api", data);
  },
  /** 获取会员卡来源列表 */
  getMemberCardWayLists(data) {
    return axios.post("/vip_services/common/get_member_card_way_list.api", data);
  },
  /** 会员卡 通行证录入 */
  cardReceive(data) {
    return axios.post("/vip_services/member_card/card_receive.api", data);
  },
  /** 会员卡 通行证快捷录入 */
  cardReceiveIndie(data) {
    return axios.post("/vip_services/member_card/card_receive_indie.api", data);
  },
  /** 录入前获取信息 */
  getBeforeCardReceiveInfo(data) {
    return axios.post("/vip_services/member_card/before_card_receive_info.api", data);
  },

  /** 通行证 */
  /** 获取通行证列表 */
  getPassCheckLists(data) {
    return axios.post("/vip_services/member_card_receive/list.api", data);
  },
  /** 通行证重新注册下发 */
  registered(data) {
    return axios.post("/vip_services/member_card_receive/register.api", data);
  },

  /** 优惠券 */
  /** 获取优惠券列表 */
  getGiftLists(data) {
    return axios.post("/vip_services/gift/list.api", data);
  },
  /** 新增优惠券 */
  addGift(data) {
    return axios.post("/vip_services/gift/insert.api", data);
  },
  /** 编辑优惠券 */
  updateGift(data) {
    return axios.post("/vip_services/gift/edit.api", data);
  },
  /** 修改状态 */
  changeStatus(data) {
    return axios.post("/vip_services/gift/change_status.api", data);
  },
};
/** 订单管理 */
const OrderApi = {
  /** 获取支付方式 */
  getCounterPayway(data) {
    return axios.post("/order/pay/counter_payway.api", data);
  },
  /** 酒店订单 */
  hotelOrderList(data) {
    return axios.post("/order/order/get_hotel_order.api", data);
  },
  /** 确认酒店订单 */
  confirmHotelOrder(data) {
    return axios.post("/order/order/confirm_hotel_order.api", data);
  },
  /** 入住核销酒店订单 */
  checkInHotelOrder(data) {
    return axios.post("/order/order/checkin_hotel_order.api", data);
  },
  /** 酒店订单详情 */
  hotelOrderDetail(data) {
    return axios.post("/order/order/get_hotel_order_details.api", data);
  },
  /** 修改入住时间 */
  editCheckTime(data) {
    return axios.post("/order/order/change_hotel_time.api", data);
  },
  /** 门票订单 */
  hotelTicketList(data) {
    return axios.post("/order/order/get_ticket_order.api", data);
  },
  /** 门票订单支付方式列表 */
  getOrderTicketPayWayList(data) {
    return axios.post("/basic_data/common/order_ticket_pay_way_list.api", data);
  },
  /** 门票订单详情 */
  hotelTicketDetail(data) {
    return axios.post("/order/order/get_ticket_order_details.api", data);
  },
  /** 获取订单打印信息 */
  printOrder(data) {
    return axios.post("/order/common/print_paper_order.api", data);
  },
  /** 编辑常用出行人 */
  editTourist(data) {
    return axios.post("/order/order/update_reservation.api", data);
  },
  /** 导出常用出行人模板 */
  exportTemplate(data) {
    return axios.post("/order/order/export_reservation.api", data);
  },
  /** 导入常用出行人模板 */
  importTemplate(data) {
    return axios.post("/order/order/import_reservation.api", data);
  },
  /** 发起退款 */
  initRefund(data) {
    return axios.post("/order/order/initiate_refund_order.api", data);
  },
  /** 确认退款 */
  sureRefund(data) {
    return axios.post("/order/order/confirm_refund_order.api", data);
  },
  /** 退费管理列表 */
  refundList(data) {
    return axios.post("/order/refund_rule/get_refund_rule_list.api", data);
  },

  /** 修改退费管理状态 */
  setRefundStatus(data) {
    return axios.post("/order/refund_rule/change_refund_rule_status.api", data);
  },
  /** 门店订单 */
  polymerizationList(data) {
    return axios.post("/order/order/get_polymerization_order.api", data);
  },
  /** 活动订单 */
  activeList(data) {
    return axios.post("/order/order/get_active_order.api", data);
  },
  /** 活动订单 */
  activeDetail(data) {
    return axios.post("/order/order/get_active_order_details.api", data);
  },
  /** 代理商订单 */
  agentList(data) {
    return axios.post("/order/order/get_agent_ticket_order.api", data);
  },
  /** 代理商详情 */
  agentDetail(data) {
    return axios.post("/order/order/get_agent_ticket_order_details.api", data);
  },
  /** 代理商-游客列表 */
  agentTourist(data) {
    return axios.post(
      "/order/order/get_agent_ticket_order_reservation.api",
      data
    );
  },
  /** 代理商-确认订单 */
  confirmAgentOrder(data) {
    return axios.post("/order/order/agent_ticket_order_confirm.api", data);
  },
  /** 代理商-线下订单支付确认文件上传 */
  insertPhoto(data) {
    return axios.post(
      "/order/order/agent_ticket_order_confirm_file_add.api",
      data
    );
  } /** 代理商-线下订单支付确认文件删除 */,
  delPhoto(data) {
    return axios.post(
      "/order/order/agent_ticket_order_confirm_file_del.api",
      data
    );
  },
  /** 门店订单详情 */
  hotelPolymerizationDetail(data) {
    return axios.post(
      "/order/order/get_polymerization_order_details.api",
      data
    );
  },
};
/** 门店管理 */
const StoreApi = {
  // 门店管理
  /** 门店列表 */
  getPolymerizations(data) {
    return axios.post(
      "/polymerization/polymerization/get_polymerizations.api",
      data
    );
  },
  /** 新增门店 */
  addPolymeriza(data) {
    return axios.post(
      "/polymerization/polymerization/add_polymerization.api",
      data
    );
  },
  /** 编辑门店 */
  editPolymeriza(data) {
    return axios.post(
      "/polymerization/polymerization/edit_polymerization.api",
      data
    );
  },
  /** 编辑状态 */
  updatePolymerizaStatus(data) {
    return axios.post(
      "/polymerization/polymerization/update_polymerization_status.api",
      data
    );
  },
  /** 入账配置 */
  setPolymerizaDivide(data) {
    return axios.post(
      "/polymerization/polymerization/set_polymerizations_divide.api",
      data
    );
  },
  /** 获取iot列表 */
  getIotLists(data) {
    return axios.post("/polymerization/common/get_iot_list", data);
  },
  /** 新增门店iot设备 */
  setPolymerizationIot(data) {
    return axios.post(
      "/polymerization/polymerization/set_polymerization_iot.api",
      data
    );
  },
  /** 移除门店iot设备 */
  removePolymerizationIot(data) {
    return axios.post(
      "/polymerization/polymerization/remove_polymerization_iot.api",
      data
    );
  },

  // 商品管理
  /** 分类项目列表 */
  getClassesOptions(data) {
    return axios.post(
      "/basic_data/common/polymerization_classes_select.api",
      data
    );
  },
  /** 获取商品列表 */
  getGoodsLists(data) {
    return axios.post("/polymerization/polymerization/get_goods.api", data);
  },
  /** 新增商品 */
  addGoods(data) {
    return axios.post("/polymerization/polymerization/add_goods.api", data);
  },
  /** 编辑商品 */
  editGoods(data) {
    return axios.post("/polymerization/polymerization/edit_goods.api", data);
  },
  /** 修改商品状态 */
  updateGoodsStatus(data) {
    return axios.post(
      "/polymerization/polymerization/update_goods_status.api",
      data
    );
  },
  /** 修改库存 */
  setStocks(data) {
    return axios.post("/polymerization/polymerization/set_stocks.api", data);
  },
  /** 库存列表 */
  getStocks(data) {
    return axios.post("/polymerization/polymerization/get_stocks.api", data);
  },
  /** 条形码列表 */
  getBarcode(data) {
    return axios.post("/polymerization/polymerization/get_barcode.api", data);
  },
  /** 添加条形码 */
  addBarcode(data) {
    return axios.post("/polymerization/polymerization/add_barcode.api", data);
  },
  /** 删除条形码 */
  delBarcode(data) {
    return axios.post("/polymerization/polymerization/del_barcode.api", data);
  },
  /** 获取后厨屏幕列表 */
  getGoodsMakeScreenList(data) {
    return axios.post("/polymerization/common/get_goods_make_screen_list", data);
  },


  // 门店分类
  /** 分类列表 */
  getClassesList(data) {
    return axios.post("/polymerization/polymerization/get_classes.api", data);
  },
  /** 新增分类 */
  addClasses(data) {
    return axios.post("/polymerization/polymerization/add_classes.api", data);
  },
  /** 编辑分类 */
  editClasses(data) {
    return axios.post("/polymerization/polymerization/edit_classes.api", data);
  },
  /** 编辑状态 */
  setUpdateStatus(data) {
    return axios.post("/polymerization/polymerization/update_status.api", data);
  },
};
/** 报表中心 */
const AnalyseApi = {
  /** 园区汇总-汇总模式 */
  getScenicSummary(data) {
    return axios.post("/report_center/summary/scenic.api", data);
  },
  /** 门店汇总-明细 */
  getStoreSummary(data) {
    return axios.post("/report_center/summary/polymerization.api", data);
  },
  /** 门店汇总-汇总 */
  getStoreSummaryTotal(data) {
    return axios.post("/report_center/summary/polymerization_store.api", data);
  },
  /** 门店汇总-分类汇总 */
  getGoodsClassifySummary(data) {
    return axios.post("/report_center/summary/goods_classes.api", data);
  },
  /** 门店汇总-分类汇总 */
  getGoodsSummary(data) {
    return axios.post("/report_center/summary/goods.api", data);
  },
  /** 门店汇总-商品明细 */
  getGoodsDetail(data) {
    return axios.post("/report_center/summary/goods_list.api", data);
  },
  /** 代理商汇总-汇总模式 */
  getAgentSummary(data) {
    return axios.post("/report_center/summary/agent.api", data);
  },
  /** 酒店汇总 */
  getHotelSummary(data) {
    return axios.post("/report_center/summary/hotel.api", data);
  },
  /** 活动汇总 */
  getActivitySummary(data) {
    return axios.post("/report_center/summary/active.api", data);
  },

  // 成本分摊汇总
  /** 成本汇总 */
  getCost(data) {
    return axios.post("/report_center/summary/cost.api", data);
  },
};
/** 代理商管理 */
const AgentApi = {
  /** 代理商列表*/
  getAgentList(data) {
    return axios.post("/agent_management/agent/agent_list.api", data);
  },
  /** 代理商状态*/
  changeAgentStatus(data) {
    return axios.post("/agent_management/agent/change_agent_status.api", data);
  },
  /** 编辑代理商*/
  editAgent(data) {
    return axios.post("/agent_management/agent/edit_agent.api", data);
  },
  /** 新增代理商*/
  addAgent(data) {
    return axios.post("/agent_management/agent/add_agent.api", data);
  },
  /** 重置密码*/
  resetPassword(data) {
    return axios.post("/agent_management/agent/reset_pass.api", data);
  },
  /** 代理商类型*/
  agentTypeOptions(data) {
    return axios.post("/agent_management/common/get_agent_type_list.api", data);
  },
  /** 已配置门票列表*/
  getConfigTickets(data) {
    return axios.post("/agent_management/agent/agent_ticket_list.api", data);
  },
  /** 修改配置门票状态*/
  configTicketsStatus(data) {
    return axios.post(
      "/agent_management/agent/change_agent_ticket_status.api",
      data
    );
  },
  /** 获取门票*/
  ticketOptions(data) {
    return axios.post(
      "/agent_management/common/get_ticket_for_agent.api",
      data
    );
  },
  /** 优惠类型*/
  discountOptions(data) {
    return axios.post(
      "/agent_management/common/get_agent_ticket_type_list.api",
      data
    );
  },
  /** 已配置门票详情*/
  configTicketsDetail(data) {
    return axios.post(
      "/agent_management/agent/get_agent_ticket_info.api",
      data
    );
  },
  /** 新增门票*/
  addTicket(data) {
    return axios.post("/agent_management/agent/add_agent_ticket.api", data);
  },
  /** 编辑门票*/
  editTicket(data) {
    return axios.post("/agent_management/agent/edit_agent_ticket.api", data);
  },
};
const Synthesize = {
  /** 获取监控设备列表*/
  getIntegrateMonitor(data) {
    return axios.post("/integrated_management/monitor/list.api", data);
  },
};
/**
 * 销售管理
 */
const SaleApi = {
	/**
	 * 我的权限内门店列表
	 */
  getMymerchant(data) {
    return axios.post("/scenic_spot/cashier/getmymerchant.api", data);
  },
	/**
	 * 获得当前门店的商品分类
	 */
  getGoodtype(data) {
    return axios.post("/scenic_spot/cashier/getgoodtype.api", data);
  },
	/**
	 * 获得商品列表信息
	 */
  getGoodslist(data) {
    return axios.post("/scenic_spot/cashier/getgoodslist.api", data);
  },
	/**
	 * 确认订单接口
	 */
  addOrder(data) {
    return axios.post("/scenic_spot/cashier/addorder.api", data);
  },
	/**
	 * 组合订单-购物车数据操作
	 */
  addShopCart(data) {
    return axios.post("/scenic_spot/common/shop_cart.api", data);
  },
	/**
	 * 组合订单-购物车数据明细
	 */
  getShopCartList(data) {
    return axios.post("/scenic_spot/common/shop_cart_list.api", data);
  },
	/**
	 * 组合订单支付方式
	 */
  getPayWayCombine(data) {
    return axios.post("/scenic_spot/common/get_pay_way_for_combine.api", data);
  },
	/**
	 * 组合订单下单
	 */
  generateOrder(data) {
    return axios.post("/scenic_spot/combine_order/generate_order.api", data);
  },
	/**
	 * 组合订单-支付成功后调用清除购物车
	 */
  clearShopCart(data) {
    return axios.post("/scenic_spot/common/clear_shop_cart.api", data);
  },
  // 车辆
	/**
	 * 获取车辆进场时间
	 */
  getVehicleEntryTime(data) {
    return axios.post("/scenic_spot/car/search_car_records_time.api", data);
  },
	/**
	 * 获取剩余车位数量
	 */
  getRemainParkCount(data) {
    return axios.post("/scenic_spot/common/get_remain_park_count.api", data);
  },
	/**
	 * 给车辆发放优惠券
	 */
  addCarCoupon(data) {
    return axios.post("/scenic_spot/car/add_car_coupon.api", data);
  },
}

export {
  Synthesize,
  FrameApi,
  BasicApi,
  DinnerApi,
  HotelApi,
  ScenicApi,
  VipApi,
  OrderApi,
  AnalyseApi,
  StoreApi,
  AgentApi,
  SaleApi
};
