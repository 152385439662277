const agentChilds = [
  {
    path: "amanage",
    name: "amanageAgent",
    component: () => import("@/views/agent/amanage.vue"),
    children: [
      {
        path: "manage",
        name: "manageAgent",
        component: () => import("@/views/agent/children/manage.vue"),
      },
    ],
  },
];
export default agentChilds;