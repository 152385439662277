<template>
  <el-aside
    id="left-nav"
    :width="isExpand ? '250px' : '150px'"
    v-loading="isLoading"
  >
    <div>
      <div class="select-container" v-if="isExpand">
        <el-dropdown
          @command="handleCommand"
          placement="bottom"
          trigger="click"
        >
          <el-button type="primary">
            <span>{{ userInfo.realname }}</span>
            <span>{{ userInfo.posname }}</span
            ><span class="triangle"></span>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu :class="['menu-drop', !isExpand ? 'pack' : '']">
              <el-dropdown-item command="1">修改密码</el-dropdown-item>
              <el-dropdown-item command="2">安全退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="select-container" v-else>
        <el-dropdown
          @command="handleCommand"
          placement="bottom"
          trigger="click"
        >
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="userInfo.posname"
            placement="right"
          >
            <el-button type="primary">
              <span>{{ userInfo.realname }}</span>
              <span class="triangle"></span>
            </el-button>
          </el-tooltip>
          <template #dropdown>
            <el-dropdown-menu :class="['menu-drop', !isExpand ? 'pack' : '']">
              <el-dropdown-item command="1">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="menu-wp" v-if="!isLoading && navMenus.length > 0">
        <div
          :class="['first-wp', activePath === navMenus[0].path ? 'active' : '']"
        >
          <div class="first"></div>
        </div>
        <el-menu
          :default-active="activePath"
          class="el-menu-vertical-demo"
          :router="true"
        >
          <el-menu-item
            v-for="(item, index) in navMenus"
            :key="index"
            :index="item.path"
            :class="{
              active: activePath === item.path,
              before:
                index + 1 < navMenus.length &&
                navMenus[index + 1].path === activePath,
              after: index > 0 && navMenus[index - 1].path === activePath,
            }"
          >
            <template #title>
              <div class="nav-item" v-show="!isExpand">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="item.title"
                  placement="right"
                >
                  <i :class="['iconfont', item.icon]"></i>
                </el-tooltip>
              </div>
              <div class="nav-item" v-show="isExpand">
                <i :class="['iconfont', item.icon]"></i>
                  <!-- <img v-if="item.imgUrl" :src="item.imgUrl" alt="" style="width: 25px;height: 25px;margin-right: 10px;margin-top: 15px;"> -->
                <div class="name">{{ item.title }}</div>
              </div>
            </template>
          </el-menu-item>
        </el-menu>
        <div
          :class="[
            'last-wp',
            activePath === navMenus[navMenus.length - 1].path ? 'active' : '',
          ]"
        >
          <div class="last"></div>
        </div>
      </div>
    </div>
    <w-dialog
      ref="resetPasswordDialog"
      title="修改密码"
      width="30%"
      top="20vh"
      @wConfirm="handleResetPassword"
    >
      <el-form
        ref="resetPasswordFormRef"
        :model="resetPasswordFormData"
        :rules="resetPasswordFormRules"
        label-position="top"
        class="organization-form"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="old_pwd" label="旧密码">
              <el-input
                v-model="resetPasswordFormData.old_pwd"
                autocomplete="off"
                placeholder="请输入旧密码"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="new_pwd" label="新密码">
              <el-input
                v-model="resetPasswordFormData.new_pwd"
                autocomplete="off"
                placeholder="请输入新密码"
                clearable
                show-password
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="new_pwd2" label="确认密码">
              <el-input
                v-model="resetPasswordFormData.new_pwd2"
                autocomplete="off"
                placeholder="请再次输入新密码"
                clearable
                show-password
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </el-aside>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import cookie from "@/utils/cookie";
import { ElMessage } from "element-plus";
import { FrameApi } from "@/plugins/api.js";
import { checkStr } from "@/utils/common.js";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const menuToken = store.state.menuToken;
    const isExpand = computed(() => store.state.Config.isExpand);

    /** 是否正在加载菜单 */
    const isLoading = ref(false);
    /** 当前模块 index */
    const moduleIndex = computed(() => store.state.menuToken.moduleIndex);
    /** 左侧菜单导航 */
    const navMenus = computed(() => {
      return menuToken.moduleIndex !== ""
        ? menuToken.menus[menuToken.moduleIndex].childRoute
        : [];
    });
    /** 监听 模块 index 改变 */
    watch(moduleIndex, () => {
      if (moduleIndex.value !== "") {
        // 获取用户菜单/按钮权限
        userInfo.realname && getUserRole();
      }
    });

    /** 获取菜单/按钮权限 */
    const getUserRole = () => {
      isLoading.value = true;
      let data = {
        module_token: menuToken.menus[menuToken.moduleIndex].token,
      };
      FrameApi.getUserRole(data).then((res) => {
        if (res.Code === 200) {
          // console.log("quanx",res.Data)
          store.commit("setMenuTokens", res.Data.menu_node_list);
          store.commit("setBtnMenus");
          /** 设置初始路由 */
          setRouter(route.path);
        } else {
          const msg = res.Message ? res.Message : "获取菜单权限失败！";
          ElMessage.error(msg);
        }
        isLoading.value = false;
      });
    };

    /** 默认激活菜单 path */
    let activePath = ref("");

    /** 用户信息 */
    let userInfo = {
      realname: cookie.getCookie("user_name"),
      posname: cookie.getCookie("duty_name"),
    };
	if(!userInfo.realname){
		userInfo.realname = sessionStorage.getItem("user_name")
		userInfo.posname = sessionStorage.getItem("duty_name")
	}

    /** 设置路由 */
    const setRouter = (value) => {
      if (navMenus.value.length > 0) {
        const paths = navMenus.value.map((item) => item.path);
        const path = paths.find((item) => value.startsWith(item));
        if (path) {
          activePath.value = path;
        } else if (paths.length > 0 && paths[0].startsWith(value)) {
          activePath.value = paths[0];
          router.push(paths[0]);
        }
        const index = navMenus.value.findIndex(
          (item) => item.path === activePath.value
        );
        store.commit("setMenuIndex", index);
      }
    };
    function handleOut() {
      // 清除cookie
      cookie.delCookie("user_name");
      cookie.delCookie("user_tel");
      cookie.delCookie("user_sex");
      cookie.delCookie("duty_name");
      cookie.delCookie("project-logo");
      router.push("/login");
      store.commit("setIsExpand", true);
      store.commit("menuTokenInit");
    }
    // 退出登录处理
    const handleCommand = (command) => {
      switch (command) {
        case "1":
          resetPasswordDialog.value.show();
          return true;
        case "2":
          handleOut();
          return true;
        default:
          return false;
      }
    };
    const resetPasswordDialog = ref(null);
    const resetPasswordFormRef = ref(null);
    const resetPasswordFormData = ref({
      old_pwd: "",
      new_pwd: "",
      new_pwd2: "",
    });

    const resetPasswordFormRules = {
      old_pwd: [
        {
          required: true,
          message: "请输入旧密码",
          trigger: "blur",
        },
      ],
      new_pwd: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
      ],
      new_pwd2: [
        {
          required: true,
          message: "请再次输入新密码",
          trigger: "blur",
        },
      ],
    };

    function handleResetPassword() {
      if (resetPasswordFormRef.value) {
        resetPasswordFormRef.value.validate((valid) => {
          if (valid) {
            if (
              resetPasswordFormData.value.new_pwd !=
              resetPasswordFormData.value.new_pwd2
            ) {
              ElMessage.error({
                message: "两次密码不一致！",
              });
              return false;
            }
            if (!checkStr(resetPasswordFormData.value.new_pwd, "pwd2")) {
              ElMessage.warning("新密码必须包含字母和数字，且长度大于等于6！");
              return false;
            }
            FrameApi.editPassword({
              old_password: resetPasswordFormData.value.old_pwd,
              new_password: resetPasswordFormData.value.new_pwd,
            }).then((res) => {
              if (res.Code == 200) {
                ElMessage.success({
                  message: "修改成功，请重新登录！",
                });
                resetPasswordDialog.value.close();
                setTimeout(() => {
                  handleOut();
                }, 600);
              } else {
                ElMessage.error(res.Message);
              }
            });
          }
        });
      }
    }
    /** 监听路由改变 */
    watch(() => route.path, setRouter);
    return {
      isLoading,
      userInfo,
      handleCommand,
      navMenus,
      activePath,
      isExpand,
      resetPasswordDialog,
      handleResetPassword,
      resetPasswordFormRef,
      resetPasswordFormData,
      resetPasswordFormRules,
    };
  },
};
</script>

<style lang="scss">
.menu-drop {
  min-width: 218px;
  > .el-dropdown-menu__item {
    justify-content: center;
  }
}
.menu-drop.pack {
  min-width: 120px;
}
.el-aside {
  background-color: var(--bg-color);
  color: var(--el-text-color-primary);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .select-container {
    margin: 20px 0 0 0;
    padding: 0 16px;
    width: 100%;

    .el-dropdown {
      width: 100%;

      .el-button {
        width: 100%;
        background-color: var(--search-bg-color);
        border: none;
        color: var(--theme-color);
        font-size: 16px;
        padding: 16px 0;
        border-radius: 4px;

        > span {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 16px;

          .triangle {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 8px solid var(--theme-color);
          }
        }
      }
    }
  }

  .menu-wp {
    .first-wp {
      background-color: var(--bg-color);
      .first {
        height: 30px;
        border-radius: 0 0 30px 0;
        background-color: var(--bg-color);
      }
    }
    .first-wp.active {
      background-color: var(--theme-bg-color);
    }
    .last-wp {
      background-color: var(--bg-color);
      .last {
        height: 30px;
        border-radius: 0 30px 0 0;
        background-color: var(--bg-color);
      }
    }
    .last-wp.active {
      background-color: var(--theme-bg-color);
    }
    .el-menu {
      background-color: var(--bg-color);
      margin-left: 18px;
      border-right: none;
      .el-menu-item {
        padding: 0 !important;
        .nav-item {
          width: 100%;
          height: 100%;
          display: flex;
          padding-left: 40px;
          background-color: var(--bg-color);
          color: var(--text-third-color);
          > i {
            margin-right: 12px;
            font-size: 22px;
          }
          .name {
            flex: 1;
            text-align: left;
          }
        }
      }
      .el-menu-item.active {
        color: var(--theme-color);
        font-weight: bold;
        border-radius: 28px 0 0 28px;
        .nav-item {
          border-radius: 28px 0 0 28px;
          color: var(--theme-color);
          background-color: var(--theme-bg-color);
        }
      }
      .el-menu-item.active:hover {
        background-color: var(--bg-color);
      }
      .el-menu-item.before {
        background-color: var(--theme-bg-color);
        .nav-item {
          border-radius: 0 0 30px 0;
          background-color: var(--bg-color);
        }
      }
      .el-menu-item.after {
        background-color: var(--theme-bg-color);
        .nav-item {
          border-radius: 0 30px 0 0;
          background-color: var(--bg-color);
        }
      }
    }
  }
}
</style>
