import {
	createRouter,
	createWebHashHistory
} from "vue-router";
import cookie from "../utils/cookie";

import basicChilds from "./module/basic-childs.js";
import memberChilds from "./module/member-childs.js";
import hotelChilds from "./module/hotel-childs.js";
import diningChilds from "./module/dining-childs.js";
import scenicChilds from "./module/scenic-childs.js";
import orderChilds from "./module/order-childs.js";
import storeManageChilds from "./module/storeManage-childs.js";
import agentChilds from "./module/agent-childs.js";
import analyseChilds from "./module/analyse-child.js";
import synthesizeChilds from "./module/synthesize-child.js";
const routes = [{
		path: "/",
		name: "Index",
		redirect: "/home",
	},
	{
		path: "/home",
		name: "Home",
		component: () => import("@/views/home/index.vue"),
		children: [],
	},
	{
		path: "/test",
		name: "Test",
		component: () => import("@/views/test/index.vue"),
		children: [],
	},
	{
		path: "/test-camera",
		name: "Camera",
		component: () => import("@/views/test/camera.vue"),
		children: [],
	},
	{
		path: "/basic",
		name: "Basic",
		component: () => import("@/views/basic/index.vue"),
		children: basicChilds,
	},
	{
		path: "/analyse",
		name: "Analyse",
		component: () => import("@/views/analyse/index.vue"),
		children: analyseChilds,
	},
	{
		path: "/synthesize",
		name: "Synthesize",
		component: () => import("@/views/synthesize/index.vue"),
		children: synthesizeChilds,
	},
	{
		path: "/member",
		name: "Member",
		component: () => import("@/views/member/index.vue"),
		children: memberChilds,
	},
	{
		path: "/hotel",
		name: "Hotel",
		component: () => import("@/views/hotel/index.vue"),
		children: hotelChilds,
	},
	{
		path: "/dining",
		name: "Dining",
		component: () => import("@/views/dining/index.vue"),
		children: diningChilds,
	},
	{
		path: "/scenic",
		name: "Scenic",
		component: () => import("@/views/scenic/index.vue"),
		children: scenicChilds,
	},
	{
		path: "/order",
		name: "Order",
		component: () => import("@/views/order/index.vue"),
		children: orderChilds,
	},
	{
		path: "/storeManage",
		name: "StoreManage",
		component: () => import("@/views/storeManage/index.vue"),
		children: storeManageChilds,
	},
	{
		path: "/invoice",
		name: "Invoice",
		component: () => import("@/views/invoice/index.vue"),
		children: [],
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/login/index.vue"),
	},
	{
		path: "/init",
		name: "Init",
		component: () => import("@/views/login/init.vue"),
	},
	{
		path: "/oauth",
		name: "Oauth",
		component: () => import("@/views/login/oauth.vue"),
	},
	{
		path: "/agent",
		name: "Agent",
		component: () => import("@/views/agent/index.vue"),
		children: agentChilds,
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach((to, from, next) => {

	let isLogin = cookie.getCookie("user_name");
	if(!isLogin && sessionStorage.getItem('user_name')){
		isLogin = sessionStorage.getItem('user_name')
	}
	if (!isLogin) {
		if (to.path !== "/login" && to.path !== "/init" && to.path !== "/oauth") {
			next("/login");
		} else {
			next();
		}
	} else {
		next();
	}

	window.document.title = "蜜乐岛";
});

router.afterEach(() => {
	window.scrollTo(0, 0);
});

export default router;