<template>
	<div v-click-outside.stop="handleClickOutside">
		<el-input ref="serachInputRef" :type="type" :autofocus="true" clearable class="search-input" v-model="keyBoardValue" :placeholder="placeholder" @input="inputEvent"
			@focus="showKey" @keyup.enter="onEnter" v-if="isShowInput" rows="3">
			<template #suffix v-if="isSearchIcon">
				<el-icon class="el-input__icon">
					<Search />
				</el-icon>
			</template>
			<template #append v-if="isShowAppend">元</template>
		</el-input>
		<div class="keyboard" v-if="keyBoard.showKeyboard">
			<slot name="operate"></slot>
			<ul class="">
				<li v-for="(key,index) in keyBoard.keyList" :key="index" track-by="$index" v-text="key"
					@click="clickKey(key)"></li>
			</ul>
		</div>
	</div>

</template>
<script setup>

	/**
	 * @author lili
	 * @param {String} v-model
	 * @param {function}  inputEvent 键入触发
	 * @param {String} placeholder
	 * @param {String} isSearchIcon  是否显示尾部图标
	 * @param {String} type  输入类型
	 * */

	import {
		reactive,
		watch,
		ref,
		defineExpose,
		nextTick,
		toRefs
	} from 'vue';
	import {
		Search
	} from "@element-plus/icons-vue";
	const vClickOutside = {
		mounted(el, binding) {
			function eventHandler(e) {
				if (el.contains(e.target)) {
					return false
				}
				// 如果绑定的参数是函数，正常情况也应该是函数，执行
				if (binding.value && typeof binding.value === 'function') {
					binding.value(e)
				}
			}
			// 用于销毁前注销事件监听
			el.__click_outside__ = eventHandler
			// 添加事件监听
			document.addEventListener('click', eventHandler)
		},
		beforeUnmount(el) {
			// 移除事件监听
			document.removeEventListener('click', el.__click_outside__)
			// 删除无用属性
			delete el.__click_outside__
		}
	}

	function inputEvent() {
		emit('update:modelValue', keyBoardValue.value)
		emit('inputEvent')
	}
	// 按enter键
	function onEnter() {
		emit('onEnter')
	}

	//点击组件外事件
	function handleClickOutside() {
		keyBoard.showKeyboard = false
	}
	const serachInputRef = ref(null)

	function serachInputRefFocus() {
		setTimeout(() => {
			nextTick(() => {
				serachInputRef.value.focus()
			})
		})
	}
	defineExpose({
		serachInputRefFocus,
		clickKey,
		showKey,
		clearContent,
		handleClickOutside,
		closeKey,
	})

	const emit = defineEmits(['update:modelValue', 'inputEvent', 'onEnter', 'determineBtn', 'onShowKey'])
	const props = defineProps({
		placeholder: {
			type: String,
			default: ''
		},
		modelValue: {
			type: [String, Number],
			default: ''
		},
		isSearchIcon: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'text'
		},
		isShowInput: {
			type: Boolean,
			default: true
		},
		leftNum: {
			type: [String, Number],
			default: '0'
		},
		// 是否显示输入框后置内容
		isShowAppend: {
			type: Boolean,
			default: false
		}
	})
	const {
		modelValue
	} = toRefs(props)
	watch(modelValue, (newValue, oldValue) => {
		keyBoardValue.value = newValue
	})


	const keyBoard = reactive({
		keyList: [],
		showKeyboard: false,
	})

	function showKey() {
		keyBoard.showKeyboard = true;
		emit('onShowKey');
	}
	function closeKey() {
		keyBoard.showKeyboard = false;
	}
	const keyBoardValue = ref('')
	function clearContent() {
		keyBoardValue.value = '';
	}

	function clickKey(key) {
		if (key == '确定') {
			keyBoard.showKeyboard = false
			emit('determineBtn');
		} else {
			keyBoardValue.value += key
			emit('update:modelValue', keyBoardValue.value)
			emit('inputEvent', keyBoardValue.value)
		}

	}

	function ready() {
		keyBoard.keyList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.','0',  '确定'];
	}
	ready();
</script>

<style lang="scss" scoped>
	.search-input {
		/* width: 240px; */
		/* background-color: var(--text-white-color) !important; */

		::-webkit-input-placeholder {
			/* color: var(--theme-color); */
		}
	}

	.el-input__inner {
		background-color: var(--text-white-color) !important;
		border-radius: 50px;
	}

	.keyboard {
		display: flex;
		margin-right: 10px;
		position: absolute;
		margin: 10px 0 0 0;

		ul {

			/* position: absolute; */
			padding: 0;
			list-style: none;
			user-select: none;
			z-index: 9999;
			box-shadow: 2px 1px 2px 2px var(--search-uncheck-bg-color);
			padding: 5px;
			background-color: var(--search-uncheck-bg-color);
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			li {
				float: left;
				margin: 2px;
				width: 60px;
				height: 60px;
				line-height: 60px;
				text-align: center;
				background: var(--select--bg-color);
				border: 1px solid var(--select--bg-color);
				color: var(--text-color);
				border-radius: 5px;

				&:hover {
					position: relative;
					border-color: gray;
					cursor: pointer;
				}

				&:active {
					top: 1px;
					left: 1px;
				}
			}
		}

		.tab,
		.delete {
			width: 70px;
		}

		.capslock {
			width: 80px;
		}

		.enter {
			width: 77px;
		}

		.shift {
			width: 102px;
		}

		.space {
			clear: left;
			width: 660px;
		}

		.shifted {
			position: relative;
			top: 1px;
			left: 1px;
			border-color: #e5e5e5;
			cursor: pointer;
		}

		.capsed {
			position: relative;
			top: 1px;
			left: 1px;
			border-color: #e5e5e5;
			cursor: pointer;
		}
	}
</style>