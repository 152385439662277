const menuToken = {
	state: () => ({
		/** 全局 菜单 权限 */
		menus: [{
			index: 0,
			title: "系统首页",
			path: "/home",
			token: "empty",
			childRoute: [{
				title: "系统首页",
				path: "/home",
				token: "empty",
				icon: "icon-a-zu5516",
			},],
		},],
		/** 当前模块 权限列表 */
		moduleTokens: [],
		/** 当前菜单 按钮 权限列表 */
		menuTokens: [],
		/** 当前选择的 模块 index */
		moduleIndex: "",
		/** 当前选择的 菜单 index */
		menuIndex: "",
		/** 当前选择的按钮 菜单 index*/
		btnIndex: "",
	}),
	getters: {
		btnMenus(state) {
			let arr = [];
			const compare = (property) => {
				return function (a, b) {
					var value1 = a[property];
					var value2 = b[property];
					return value1 - value2;
				};
			};
			const pathHash = window.location.hash;
			if (
				pathHash != "#/home" &&
				state.menus[state.moduleIndex] &&
				state.menus[state.moduleIndex].childRoute[state.menuIndex]
			) {
				arr = state.menus[state.moduleIndex].childRoute[
					state.menuIndex
				].childRoute.sort(compare("index"));
			}
			return arr;
		},
	},
	mutations: {
		/** menus 初始化 */
		menuTokenInit(state) {
			state.menus = [{
				title: "系统首页",
				path: "/home",
				token: "empty",
				childRoute: [{
					title: "系统首页",
					path: "/home",
					token: "empty",
					icon: "icon-a-zu5516",
				},],
			},];
			state.moduleTokens = [];
			state.menuTokens = [];
			state.moduleIndex = "";
			state.menuIndex = "";
			state.btnIndex = "";
		},
		/** 设置 模块 menus */
		setModuleMenus(state) {
			if (state.moduleTokens.length > 0) {
				const compare = (property) => {
					return function (a, b) {
						var value1 = a[property];
						var value2 = b[property];
						return value1 - value2;
					};
				};

				state.moduleTokens.forEach((item) => {
					switch (item) {
						case "m_AIPfDvU8eoEi3xy3kYKlX70vWhdoiFH4Lx17WRd6fSbHPQhj":
							state.menus.push({
								index: 1,
								title: "基础数据",
								path: "/basic",
								token: item,
								childRoute: [],
							});
							break;

						case "m_Wm4EbPa9A7IaijPij86p35ML2Wep7Z5kwVY8zpNOG0Q3hHtA":
							state.menus.push({
								index: 2,
								title: "会员服务",
								path: "/member",
								token: item,
								childRoute: [],
							});
							break;
						case "m_ghzsyoa6ahIx17NswffPj8MgPCV3rygNXsZiEvrsdkaaxI6U":
							state.menus.push({
								index: 3,
								title: "销售管理",
								path: "/scenic",
								token: item,
								childRoute: [],
							});
							break;
						case "m_m57XTdz3nspudBTSc87CbfLmvwM4abNfJGK8yqY9eYyuf":
							state.menus.push({
								index: 4,
								title: "门店管理",
								path: "/storeManage",
								token: item,
								childRoute: [],
							});
							break;
						case "m_ETH2SD5y5TDO0DixeraieNzXWX20vjzjPSmmNNymJmLSx":
							state.menus.push({
								index: 5,
								title: "订单管理",
								path: "/order",
								token: item,
								childRoute: [],
							});
							break;
						// case "m_xA2L8z3TrLbEYUHip3XnkAK8iUpGXmQUqW2KkcyfEkJM1Wty":
						//   state.menus.push({
						//     index: 4,
						//     title: "代理商管理",
						//     path: "/agent",
						//     token: item,
						//     childRoute: [],
						//   });
						//   break;
						// case "m_kOxUpXi0e9fTqOwpzm8EGmxgdPygZxC29f83kig030hukZkb":
						//   state.menus.push({
						//     index: 3,
						//     title: "酒店管理",
						//     path: "/hotel",
						//     token: item,
						//     childRoute: [],
						//   });
						//   break;
						// case "m_5wNtUmR9xyrXZqAYWcY5GPqCvS0RizFkk0YvNaZjIUTn9n8e":
						//   state.menus.push({
						//     index: 4,
						//     title: "餐厅管理",
						//     path: "/dining",
						//     token: item,
						//     childRoute: [],
						//   });
						//   break;
						// case "电子发票":
						//   state.menus.push({
						//     index: 7,
						//     title: "电子发票",
						//     path: "/invoice",
						//     token: item,
						//     childRoute: [],
						//   });
						//   break;
						case "m_eTTXVUu6z683NzbpHUVWBSREWT3w7dxCyAHx8bDkQAqSx":
							state.menus.push({
								index: 11,
								title: "报表中心",
								path: "/analyse",
								token: item,
								childRoute: [],
							});
							break;
						case "m_5daKXPgFnIVsorcPp1Ut5ladTTl7x89nNht40DBiNjbFfCbM":
							state.menus.push({
								index: 12,
								title: "综合管理",
								path: "/synthesize",
								token: item,
								childRoute: [],
							});
							break;
					}
				});
				state.menus.sort(compare("index"));
				var obj = {};
				state.menus = state.menus.reduce(function (item, next) {
					obj[next.token] ? '' : obj[next.token] = true && item.push(next);
					return item;
				}, []);
			}
		},
		/** 设置 菜单/按钮 menus */
		setBtnMenus(state) {
			const dataHandle = (menu1, menu2) => {
				let routes = state.menus[state.moduleIndex].childRoute;
				let paths = routes.map((item) => item.path);
				let index = paths.findIndex((item) => item === menu1.path);
				if (index != -1) {
					let childRoutes = routes[index].childRoute;
					let childPaths = childRoutes.map((item) => item.path);
					if (!childPaths.includes(menu2.path)) {
						childRoutes.push(menu2);
					}
				} else {
					menu1.childRoute = [menu2];
					routes.push(menu1);
				}
			};
			if (state.menuTokens.length > 0) {
				state.menuTokens.forEach((item) => {
					switch (item) {
						case "n_hDbtPj8qdfBAHVuOKZmo09Lw98ds":
							dataHandle({
								index: 0,
								title: "组织人员",
								path: "/basic/user",
								icon: "icon-a-zu4702",
								token: "empty",
								childRoute: [],
							}, {
								title: "组织与成员",
								path: "/basic/user/members",
								token: item,
								index: 0,
							});
							break;
						case "r_QUayzOCXbLc2eJGu31ASiqNgKE0x":
							dataHandle({
								index: 0,
								title: "组织人员",
								path: "/basic/user",
								icon: "icon-a-zu4702",
								token: "empty",
								childRoute: [],
							}, {
								title: "角色管理",
								path: "/basic/user/role",
								token: item,
								index: 1,
							});
							break;
						case "r_TZtls3k1wYa2JgehxorV5jS8Rbi6":
							dataHandle({
								index: 1,
								title: "资源管理",
								path: "/basic/resource",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "资源管理",
								path: "/basic/resource/resmanage",
								token: item,
								index: 0,
							});
							break;
						// case "r_HALnIlC6aevhdfrEspQDX0BoWJ9Z":
						//   dataHandle(
						//     {
						//       index: 1,
						//       title: "资源管理",
						//       path: "/basic/resource",
						//       icon: "icon-a-zu4702",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "资源管理",
						//       path: "/basic/resource/resmanage",
						//       token: item,
						//       index: 0,
						//     }
						//   );
						//   break;
						// case "r_VSL2bqay9WAncQ641zJBHpGjoehI":
						//   dataHandle(
						//     {
						//       index: 1,
						//       title: "资源管理",
						//       path: "/basic/resource",
						//       icon: "icon-a-zu4702",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "资源管理",
						//       path: "/basic/resource/resmanage",
						//       token: item,
						//       index: 0,
						//     }
						//   );
						//   break;
						case "r_v0C4cNam1phZP8tlOeAsV5znjGWM":
							dataHandle({
								index: 1,
								title: "资源管理",
								path: "/basic/resource",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "入口管理",
								path: "/basic/resource/entrance",
								token: item,
								index: 1,
							});
							break;
						case "n_KEHy9dB7X5XEBeEZpFsaVrtZDWOs":
							dataHandle({
								index: 1,
								title: "资源管理",
								path: "/basic/resource",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "门票管理",
								path: "/basic/resource/stmanage",
								token: item,
								index: 2,
							});
							break;
						case "车场管理":
							dataHandle({
								index: 1,
								title: "资源管理",
								path: "/basic/resource",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "车场管理",
								path: "/basic/resource/parking",
								token: item,
								index: 3,
							});
							break;
						case "r_60Wu7sPdbm4TMfGZnRAtV9oQOpJy":
							dataHandle({
								index: 1,
								title: "资源管理",
								path: "/basic/resource",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "闸机管理",
								path: "/basic/resource/gate",
								token: item,
								index: 4,
							});
							break;
						case "n_vsylKU6LKuPb8xDBnMhqZubeKLv":
							dataHandle(
								{
									index: 1,
									title: "资源管理",
									path: "/basic/resource",
									icon: "icon-a-zu4702",
									token: "empty",
									childRoute: [],
								},
								{
									title: "监控管理",
									path: "/basic/resource/monitor",
									token: item,
									index: 4,
								}
							);
							break;
						case "n_Rzh8BHK4v7FhRpGm55cIOkspvwVO":
							dataHandle({
								index: 1,
								title: "资源管理",
								path: "/basic/resource",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "IOT物联网通信",
								path: "/basic/resource/iot",
								token: item,
								index: 5,
							});
							break;
						case "mp_fiEL3l59oVL3unUrZg8IV7aTLcV":
							dataHandle({
								index: 4,
								title: "小程序管理",
								path: "/basic/miniapp",
								icon: "icon-a-zu5224",
								token: "empty",
								childRoute: [],
							}, {
								title: "小程序管理",
								path: "/basic/miniapp/minimanage",
								token: item,
								index: 0,
							});
							break;
						case "r_X0xBd3sLVTPZhUnIKRrpYjt8C5Hw":
							dataHandle({
								index: 0,
								title: "餐厅配置",
								path: "/dining/dconfig",
								icon: "icon-a-zu5210",
								token: "empty",
								childRoute: [],
							}, {
								title: "餐厅配置",
								path: "/dining/dconfig/dclist",
								token: item,
								index: 0,
							});
							break;
						case "n_eywDWSFwKuy6Pz0i3nqcTq9xLp1O":
							dataHandle({
								index: 0,
								title: "线下售票",
								path: "/scenic/sale",
								icon: "icon-a-zu5222",
								token: "empty",
								childRoute: [],
							}, {
								title: "线下售票",
								path: "/scenic/sale/olsale",
								token: item,
								index: 0,
							});
							break;
						case "c_AWLoe2ZlH8W5eDFhb8pQ":
							dataHandle({
								index: 1,
								title: "收银",
								path: "/scenic/cashier",
								icon: "icon-shouyin-icon",
								token: "empty",
								childRoute: [],
							}, {
								title: "点单",
								path: "/scenic/cashier/commodity",
								token: item,
								index: 0,
							});
							break;
						case "m_8mQqyK9L2h0CmpTtOqYbpPS8t":
							dataHandle({
								index: 2,
								title: "会员卡",
								path: "/scenic/mscard",
								icon: "icon-a-zu5224",
								token: "empty",
								childRoute: [],
							}, {
								title: "会员卡",
								path: "/scenic/mscard/membershipCard",
								token: item,
								index: 0,
							});
							break;
						case "n_2wNqyHs8KSc3JsKHiUfFPKO0zT":
							dataHandle({
								index: 3,
								title: "核销",
								path: "/scenic/woff",
								icon: "icon-a-zu5211",
								token: "empty",
								childRoute: [],
							}, {
								title: "优惠券核销",
								path: "/scenic/woff/writeOff",
								token: item,
								index: 0,
							});
							break;
						case "s_hgkekgPGj7ANbPqWJnXRqFBbEGB":
							dataHandle({
								index: 4,
								title: "通行证",
								path: "/scenic/spass",
								icon: "icon-a-zu5218",
								token: "empty",
								childRoute: [],
							}, {
								title: "通行证",
								path: "/scenic/spass/passCheck",
								token: item,
								index: 0,
							});
							break;
						case "s_XPnLXR75hDeNMtyej9pXmtnZ2tc":
							dataHandle({
								index: 5,
								title: "后厨管理",
								path: "/scenic/mkitchen",
								icon: "icon-a-zu5216",
								token: "empty",
								childRoute: [],
							}, {
								title: "后厨管理",
								path: "/scenic/mkitchen/kitchenManage",
								token: item,
								index: 0,
							});
							break;
						// case "n_KNFPbmAJsGJWpWc9PM70TT7MbGGL":
						//   dataHandle(
						//     {
						//       index: 2,
						//       title: "园区配置",
						//       path: "/scenic/sconfig",
						//       icon: "icon-a-zu5211",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "园区配置",
						//       path: "/scenic/sconfig/secnf",
						//       token: item,
						//       index: 0,
						//     }
						//   );
						//   break;
						// case "n_KSD82J2KFKI3921A07GEFC3AHSUF":
						//   dataHandle(
						//     {
						//       index: 3,
						//       title: "声音博物馆",
						//       path: "/scenic/museum",
						//       icon: "icon-a-zu5217",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "点位管理",
						//       path: "/scenic/museum/point",
						//       token: item,
						//       index: 0,
						//     }
						//   );
						//   break;
						// case "n_5A11223HFKI3921A07GEFC3A756E":
						//   dataHandle(
						//     {
						//       index: 3,
						//       title: "声音博物馆",
						//       path: "/scenic/museum",
						//       icon: "icon-a-zu5217",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "文件管理",
						//       path: "/scenic/museum/file",
						//       token: item,
						//       index: 1,
						//     }
						//   );
						//   break;
						case "h_rgV6runWlIK8fmjCuhurSxxgGtNB":
							dataHandle({
								index: 0,
								title: "房价管理",
								path: "/hotel/hprice",
								icon: "icon-a-zu5219",
								token: "empty",
								childRoute: [],
							}, {
								title: "每日房价",
								path: "/hotel/hprice/everyday",
								token: item,
								index: 0,
							});
							break;
						case "h_YOPkiXFm9STAaGJgI2oaR7Ud6eSy":
							dataHandle({
								index: 0,
								title: "房价管理",
								path: "/hotel/hprice",
								icon: "icon-a-zu5106",
								token: "empty",
								childRoute: [],
							}, {
								title: "套餐管理",
								path: "/hotel/hprice/combo",
								token: item,
								index: 1,
							});
							break;
						case "h_oQMtuRne0N3GheIkVxJgoXAtC6py":
							dataHandle({
								index: 1,
								title: "房型管理",
								path: "/hotel/htype",
								icon: "icon-a-zu5218",
								token: "empty",
								childRoute: [],
							}, {
								title: "房型管理",
								path: "/hotel/htype/tmanager",
								token: item,
								index: 0,
							});
							break;
						case "h_7Mr66pkNUUQknciOCBhbecSIiFDc":
							dataHandle({
								index: 2,
								title: "酒店配置",
								path: "/hotel/hconfig",
								icon: "icon-a-zu4706",
								token: "empty",
								childRoute: [],
							}, {
								title: "酒店配置",
								path: "/hotel/hconfig/config",
								token: item,
								index: 0,
							});
							break;
						case "h_XKj4FjKUT1W5Bk6RaM4Bw4Z7OpDm":
							dataHandle({
								index: 3,
								title: "标签管理",
								path: "/hotel/tag",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "标签管理",
								path: "/hotel/tag/tagmanage",
								token: item,
								index: 0,
							});
							break;
						case "m_0xG9vRrjYQTNMZhwliHpVUtL84I5":
							dataHandle({
								index: 0,
								title: "会员管理",
								path: "/member/mmanage",
								icon: "icon-a-zu5216",
								token: "empty",
								childRoute: [],
							}, {
								title: "会员管理",
								path: "/member/mmanage/manage",
								token: item,
								index: 0,
							});
							break;
						case "m_fXMNjt1VZCe7NWnYHJQcL3Ff":
							dataHandle({
								index: 1,
								title: "会员卡",
								path: "/member/mcard",
								icon: "icon-a-zu5224",
								token: "empty",
								childRoute: [],
							}, {
								title: "会员卡",
								path: "/member/mcard/memberCard",
								token: item,
								index: 0,
							});
							break;
						case "n_lY4yUHvPUnWTLRYLmNnDFm6HOYw5":
							dataHandle({
								index: 2,
								title: "优惠券",
								path: "/member/mcoupon",
								icon: "icon-a-zu5212",
								token: "empty",
								childRoute: [],
							}, {
								title: "优惠券",
								path: "/member/mcoupon/coupon",
								token: item,
								index: 0,
							});
							break;
						case "m_9tcGoWv0CLSowTU6Igu7":
							dataHandle({
								index: 3,
								title: "活动管理",
								path: "/member/mactivity",
								icon: "icon-a-zu5211",
								token: "empty",
								childRoute: [],
							}, {
								title: "活动券",
								path: "/member/mactivity/activity",
								token: item,
								index: 0,
							});
							break;
						// case "n_138IRptjlTvCJDZW6DS3vP1Vz2Dx":
						//   dataHandle(
						//     {
						//       index: 3,
						//       title: "AR管理",
						//       path: "/member/mar",
						//       icon: "icon-a-zu5220",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "AR管理",
						//       path: "/member/mar/ar",
						//       token: item,
						//       index: 0,
						//     }
						//   );
						//   break;
						case "o_Ed2oCykvL5PsZbqlQnWmRuiNFIXp":
							dataHandle({
								index: 0,
								title: "酒店订单",
								path: "/order/ohotel",
								icon: "icon-a-zu5209",
								token: "empty",
								childRoute: [],
							}, {
								title: "酒店订单",
								path: "/order/ohotel/hotel",
								token: item,
								index: 0,
							});
							break;
						case "o_DeQbXuYNhBU6tpmnPx8oRcdgjs2W":
							dataHandle({
								index: 1,
								title: "门票订单",
								path: "/order/otickets",
								icon: "icon-a-zu5211",
								token: "empty",
								childRoute: [],
							}, {
								title: "门票订单",
								path: "/order/otickets/tickets",
								token: item,
								index: 0,
							});
							break;
						case "gCc3mfqMdb6yqHxyKFehaqsWjxGCxd":
							dataHandle({
								index: 2,
								title: "门店订单",
								path: "/order/opay",
								icon: "icon-a-zu5642",
								token: "empty",
								childRoute: [],
							}, {
								title: "门店订单",
								path: "/order/opay/pay",
								token: item,
								index: 0,
							});
							break;
						case "o_4nV7fxhCCLHXpc7o6705NprxT4cs":
							dataHandle({
								index: 3,
								title: "活动订单",
								path: "/order/oactivity",
								icon: "icon-a-zu5212",
								token: "empty",
								childRoute: [],
							}, {
								title: "活动订单",
								path: "/order/oactivity/activity",
								token: item,
								index: 0,
							});
							break;
						case "o_78dYkJEyJS30wD86sc4VGfvp6VjZ":
							dataHandle({
								index: 3,
								title: "代理商订单",
								path: "/order/oagent",
								icon: "icon-a-zu5224",
								token: "empty",
								childRoute: [],
							}, {
								title: "代理商订单",
								path: "/order/oagent/agent",
								token: item,
								index: 0,
							});
							break;
						// case "rr_yHbApLWWNYGQuYAGJxGKcczaei":
						//   dataHandle(
						//     {
						//       index: 2,
						//       title: "退费管理",
						//       path: "/order/rpremium",
						//       icon: "icon-a-zu5212",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "退费管理",
						//       path: "/order/rpremium/premium",
						//       token: item,
						//       index: 0,
						//     }
						//   );
						//   break;
						// case "c_BZxnIHSdJDgs9QAU8tYMPzcWrhV3":
						//   dataHandle(
						//     {
						//       index: 2,
						//       title: "社群管理",
						//       path: "/member/massociation",
						//       icon: "icon-a-zu5217",
						//       token: "empty",
						//       childRoute: [],
						//     },
						//     {
						//       title: "社群管理",
						//       path: "/member/massociation/association",
						//       token: item,
						//       index: 0,
						//     }
						//   );
						//   break;
						case "n_SUVcIQiT2eq8fRA2dUfadjCsDBpH":
							dataHandle({
								index: 0,
								title: "门店管理",
								path: "/storeManage/madministra",
								icon: "icon-a-zu5216",
								token: "empty",
								childRoute: [],
							}, {
								title: "门店管理",
								path: "/storeManage/madministra/administra",
								token: item,
								index: 0,
							});
							break;
						case "n_C8AC97FF2E63D998A2A8876F41D4":
							dataHandle({
								index: 1,
								title: "商品分类",
								path: "/storeManage/mclassification",
								icon: "icon-a-zu5112",
								token: "empty",
								childRoute: [],
							}, {
								title: "商品分类",
								path: "/storeManage/mclassification/classification",
								token: item,
								index: 0,
							});
							break;
						case "n_5WwL9jpPPJ8a6vqTUQgJBbpVQ5Vx":
							dataHandle({
								index: 0,
								title: "统计报表",
								path: "/analyse/statistics",
								icon: "icon-a-zu4702",
								token: "empty",
								childRoute: [],
							}, {
								title: "园区汇总",
								path: "/analyse/statistics/scenic",
								token: item,
								index: 0,
							});
							break;
						case "n_64wYMZmuGQjMFMwS5xm8kNrBCmEj":
							dataHandle({
								index: 0,
								title: "统计报表",
								path: "/analyse/statistics",
								icon: "icon-a-zu4702",
								token: "empty",
								childRoute: [],
							}, {
								title: "门店汇总",
								path: "/analyse/statistics/store",
								token: item,
								index: 0,
							});
							break;
						case "n_6CmKwKqrBSkUY6UUVwBy2wl1jhYJ":
							dataHandle({
								index: 0,
								title: "统计报表",
								path: "/analyse/statistics",
								icon: "icon-a-zu4702",
								token: "empty",
								childRoute: [],
							}, {
								title: "成本分摊汇总",
								path: "/analyse/statistics/cost",
								token: item,
								index: 0,
							});
							break;
						// case "n_apZuXbQD2lVMAi7cBxcBnNiK8sL0":
						// 	dataHandle({
						// 		index: 0,
						// 		title: "统计报表",
						// 		path: "/analyse/statistics",
						// 		icon: "icon-a-zu4702",
						// 		token: "empty",
						// 		childRoute: [],
						// 	}, {
						// 		title: "酒店汇总",
						// 		path: "/analyse/statistics/hotel",
						// 		token: item,
						// 		index: 2,
						// 	});
						// 	break;
						// case "n_uOHEZIpAXbbLSus4Muc49QymFHcW":
						// 	dataHandle({
						// 		index: 0,
						// 		title: "统计报表",
						// 		path: "/analyse/statistics",
						// 		icon: "icon-a-zu4702",
						// 		token: "empty",
						// 		childRoute: [],
						// 	}, {
						// 		title: "活动汇总",
						// 		path: "/analyse/statistics/activity",
						// 		token: item,
						// 		index: 3,
						// 	});
						// 	break;
						// case "n_WQzOrVfMFHhJEIuEdzhCZHrWH1m6":
						// 	dataHandle({
						// 		index: 0,
						// 		title: "统计报表",
						// 		path: "/analyse/statistics",
						// 		icon: "icon-a-zu4702",
						// 		token: "empty",
						// 		childRoute: [],
						// 	}, {
						// 		title: "代理商汇总",
						// 		path: "/analyse/statistics/agent",
						// 		token: item,
						// 		index: 4,
						// 	});
						// 	break;
						case "n_chiWjOR6DLjq14vcYslYoFc2HL3":
							dataHandle({
								index: 0,
								title: "代理商管理",
								path: "/agent/amanage",
								icon: "icon-a-zu4702",
								token: "empty",
								childRoute: [],
							}, {
								title: "代理商管理",
								path: "/agent/amanage/manage",
								token: item,
								index: 0,
							});
							break;
						case "n_lJhpfnPU0w1CKlzgrnSccfSUFsr0":
							dataHandle({
								index: 0,
								title: "监控管理",
								path: "/synthesize/monitor",
								icon: "icon-a-zu4702",
								token: "empty",
								childRoute: [],
							}, {
								title: "监控",
								path: "/synthesize/monitor/monitorLive",
								token: item,
								index: 0,
							});
					}
				});
			}
			const compare = (property) => {
				return function (a, b) {
					var value1 = a[property];
					var value2 = b[property];
					return value1 - value2;
				};
			};
			state.menus[state.moduleIndex].childRoute.sort(compare("index"));
		},
		/** 设置模块权限列表 */
		setModuleTokens(state, data) {
			state.moduleTokens = data;
		},
		/** 设置菜单、按钮权限列表 */
		setMenuTokens(state, data) {
			state.menuTokens = data;
		},
		/** 判断token是否在菜单/按钮权限列表 */
		menuTokenCheck(state, token) {
			return state.menuTokens.includes(token) || token === "empty";
		},
		/** 设置当前选择的 模块  */
		setModuleIndex(state, index) {
			state.moduleIndex = index;
		},
		/** 设置当前选择的 菜单  */
		setMenuIndex(state, index) {
			state.menuIndex = index;
		},
		/** 设置当前选择的按钮 菜单 */
		setBtnIndex(state, index) {
			state.btnIndex = index;
		},
	},
	actions: {},
};

export default menuToken;