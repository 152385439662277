import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import Config from "./config/config.js";
import menuToken from "./menuToken/menuToken.js";

export default createStore({
  plugins: [
    createPersistedState({
      // 持久化到storage
      storage: window.localStorage,
      reducer (val) {
        return {
          // 持久化的store对象
          Config: val.Config,
        };
      },
    }),
  ],
  modules: {
    Config,
    menuToken
  },
});
