const diningChilds = [
  {
    path: "dconfig",
    name: "Dconfig",
    component: () => import("@/views/dining/dconfig.vue"),
    children: [
      {
        path: "dclist",
        name: "Dclist",
        component: () => import("@/views/dining/children/dclist.vue"),
      },
    ]
  }
];
export default diningChilds;
