<script setup>
	import Top from "@/components/layout/top.vue";
	import {
		useRouter,
	} from "vue-router";
	import {
		ref
	} from "vue";
	// 导入左侧菜单导航组件
	import Left from "@/components/layout/left.vue";
	
	const router = useRouter();
	
	/** 顶部 菜单导航列表 */

	let showTop = ref(false);

	router.beforeEach((to) => {
		if (to.path !== "/login" && to.path !== "/init" && to.path !== "/oauth") {
			showTop.value = true;
		} else {
			showTop.value = false;
		}
	});
</script>

<template>
	<div id="home" >
		<Top v-if="showTop"></Top>
		<div class="main-wp" id="main-wp">
			<!-- 左侧菜单组件 -->
			<Left v-if="showTop"></Left>
			<router-view></router-view>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	#home {
		display: flex;
		flex-direction: column;
		min-height: 100vh;

		.main-wp {
			flex: 1;
			display: flex;
		}
	}
</style>