<template>
  <div v-if="dialogVisible">
    <el-dialog
      v-model="dialogVisible"
      :top="top ? top : '4vh'"
      :title="title"
      :width="width"
      :close-on-click-modal="modalClick"
	  @close="iconClose"
      center
    >
      <slot></slot>
      <template #footer v-if="!hideFooter">
        <el-button
          @click="wCancel"
          type="primary"
          class="cancle"
          plain
          round
          :loading="isLoading"
          :style="{ width: btnWidth ? btnWidth : '120px' }"
          >{{ cancelText ? cancelText : "取消" }}</el-button
        >
        <el-button
          class="confirm"
          @click="wConfirm"
          round
          :loading="isLoading"
          :disabled="isLoading"
          :style="{ width: btnWidth ? btnWidth : '120px' }"
          >{{ confirmText ? confirmText : "确认保存" }}</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "w-dialog",
  props: [
    "width",
    "title",
    "top",
    "btnWidth",
    "cancelText",
    "confirmText",
    "hideFooter",
    "modalClick",
    "cancelEvent",
  ],
  emits: ["wConfirm", "iconClose","wCancel",],
  setup(props, { emit }) {
    const dialogVisible = ref(false);
    const isLoading = ref(false);
    const wConfirm = () => {
      // dialogVisible.value = false;
      emit("wConfirm", "1333");
    };
    const show = () => {
      dialogVisible.value = true;
    };
    const close = () => {
      dialogVisible.value = false;
    };
    const wCancel = () => {
      if (props.cancelEvent) {
        emit("wCancel",);
      } else {
        dialogVisible.value = false;
      }
    };
	const iconClose = () =>{
		 emit("iconClose", "1333");
		 dialogVisible.value = false;
	}

    return {
		iconClose,
      isLoading,
      show,
      close,
      wConfirm,
      dialogVisible,
      wCancel,
    };
  },
};
</script>

<style lang="scss">
.el-button {
  padding: 12px 60px;
  background-color: var(--theme-color);
}

/* .cancle {
  border: 2px solid var(--theme-color);
  padding: 10px 60px;
  color: var(--theme-color);
  background-color: var(--text-white-color);
} */

.confirm {
  color: var(--text-white-color);
}
.el-dialog {
  .el-overlay-dialog {
      overflow: hidden !important;
  }
  .el-dialog__body {
    /* max-height: 450px; */
    overflow: auto;
  }
  .el-dialog__title {
    color: var(--text-color);
    font-weight: 700;
  }
  .el-dialog__header{
    padding: 15px;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 22px;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--theme-color);
  }
  .el-dialog__footer {
    padding: 10px 25px 30px;
    .el-button {
      width: 100px;
      background-color: var(--cancel-btn-color);
      border: 1px solid var(--theme-color);
      color: var(--theme-color);
      &:nth-child(2) {
        margin-left: 80px;
        background-color: var(--btn-bg-color);
        border: 1px solid var(--btn-bg-color);
        color: var(--text-white-color);
      }
    }
  }
}
</style>
