import axios from "axios";
import encrypt from "./encrypt.js";
import cookie from "@/utils/cookie";
import { ElMessage } from "element-plus";
import getBaseUrl from "./baseUrl.js";
const url = getBaseUrl() ? getBaseUrl() : "";
const instance = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

// http请求拦截器
instance.interceptors.request.use(
  (config) => {
    if (config.data) {
      let data = encrypt.aesEncrypt(config.data);
      config.data = {
        encryption: data,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// http响应拦截器
instance.interceptors.response.use(
  (data) => {
    // 登录失效
    if (data.data.Code === 204) {
      ElMessage.error("用户登录失效！");
      // 清除cookie
      cookie.delCookie("user_name");
      cookie.delCookie("user_tel");
      cookie.delCookie("user_sex");
      cookie.delCookie("duty_name");
      cookie.delCookie("project-logo");
	  sessionStorage.clear()
      window.location.reload();
    } else {
      if (data.data.Data && data.data.Data.encryption) {
        data.data.Data = encrypt.aesDecrypt(data.data.Data.encryption);
      }
      return data.data;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
